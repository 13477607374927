import xodeUser from './User';
import xodeAPI from '../api/restapi';
import xodeWS from '../api/wsapi';

// x-ode service client interaction
class ServiceClient
{
    // instance handlers set
    constructor()
    {
        // init connected status flag
        this.connected = false;
    }

    // service callbacks

    setOnReady(serviceReady)
    {
        //set ready callback
        this.onReady = serviceReady;
    }

    setOnSignIn(onSignIn)
    {
        //set sign in callback
        this.onSignIn = onSignIn;
    }

    setOnSignUp(onSignUp)
    {
        //set sign up callback
        this.onSignUp = onSignUp;
    }
    
    setOnPosts(onPosts)
    {
        //set posts callback
        this.onPosts = onPosts;
    }

    setOnAddPostConfirm(onAddPostConfirm)
    {
        //set post add callback
        this.onAddPostConfirm = onAddPostConfirm;
    }

    setOnAddPostNotice(onAddPostNotice)
    {
        //set post added callback
        this.onAddPostNotice = onAddPostNotice;
    }

    setOnDeletePostConfirm(onDeletePostConfirm)
    {
        //set post delete callback
        this.onDeletePostConfirm = onDeletePostConfirm;
    }

    setOnDeletePostNotice(onDeletePostNotice)
    {
        //set post deleted callback
        this.onDeletePostNotice = onDeletePostNotice;
    }

    setOnPostReplies(onPostReplies)
    {
        //set post replies callback
        this.onPostReplies = onPostReplies;
    }

    setOnReplyConfirm(onReplyConfirm)
    {
        //set post reply callback
        this.onReplyConfirm = onReplyConfirm;
    }

    setOnReplyNotice(onReplyNotice)
    {
        //set post replied callback
        this.onReplyNotice = onReplyNotice;
    }

    setOnPendingNotices(onPendingNotices)
    {
        //set user while offline noctices callback
        this.onPendingNotices = onPendingNotices;
    }

    setOnPrivatePostConfirm(onPrivatePostConfirm)
    {
        //set post to private callback
        this.onPrivatePostConfirm = onPrivatePostConfirm;
    }

    setOnPrivatePostNotice(onPrivatePostNotice)
    {
        //set post to private callback
        this.onPrivatePostNotice = onPrivatePostNotice;
    }

    setOnPublicPostConfirm(onPublicPostConfirm)
    {
        //set post to public callback
        this.onPublicPostConfirm = onPublicPostConfirm;
    }

    setOnPublicPostNotice(onPublicPostNotice)
    {
        //set post to public callback
        this.onPublicPostNotice = onPublicPostNotice;
    }

    setOnUserComunity(onUserComunity)
    {
        //set user comunity callback
        this.onUserComunity = onUserComunity;
    }

    setOnConnectionLost(onConnectionLost)
    {
        //set service connection lost callback
        this.onConnectionLost = onConnectionLost;
    }

    // service setup entry point
    connectService()
    {
        //get user env id
        this.Login = this.Login.bind(this);
        xodeUser.getUserEnvironmentID(this.Login);
    }

    // current user
    get currentUser()
    {
        return xodeUser;
    }

    // close service wa api 
    disconnectWSapi()
    {
        // stop service availability periodically check
        this.StopServiceCheck()

        // exit ws api channel
        xodeWS.Close();

        // set connected status flag
        this.connected = false;
    }

    // initial login
    Login(userReady)
    {
        if (userReady)
        {
            this.Connect = this.Connect.bind(this);
            xodeAPI.LogIn(xodeUser.userEnvID, this.Connect);
        }
    }

    // connect thru ws
    Connect(sessionInfo)
    {  
        if (sessionInfo)
        {
            // set User info
            xodeUser.userId = sessionInfo.userId;
            xodeUser.userName = sessionInfo.userName;
            xodeUser.signedMode = sessionInfo.signedMode;

            // first time engagement flag
            this.userFirstTimeEngagement = sessionInfo.firstEngagement;
  
            // ws connect request
            xodeWS.Connect(sessionInfo, this);
        }
    }

    // connected to x-ode
    onConnected()
    {
        // set connected status flag
        this.connected = true;

        // report service ready
        this.onReady(true, this.userFirstTimeEngagement);

        //connected to x-ode WS-API...
        //deploy ping(chk<->ack)routine
        this.StartServiceCheck();
    }

    // user sign in
    signIn(username, password)
    {
        // set service busy
        this.onReady(false);
        
        // ws sign in request
        xodeWS.AuthenticateUser(username.toUpperCase(), password);
    }

    // sign in confirm
    onSignInConfirm(success, sessionInfo)
    {
        if (success)
        {
            //disconect former client
            this.disconnectWSapi();

            // connect new signed mode session
            this.Connect(sessionInfo);
        }
        else
        {
            //report user sign in result
            this.onSignIn(success);
        }
    }

    // user sign up
    signUp(email, password)
    {
        // set service busy
        this.onReady(false);

        // ws sign up request
        xodeWS.RegisterUser(email.toUpperCase(), password);
    }

    // sign up confirm
    onSignUpConfirm(success, sessionInfo)
    {
        if (success)
        {
            // set User info
            xodeUser.userId = sessionInfo.userId;
            xodeUser.userName = sessionInfo.userName;
            xodeUser.signedMode = sessionInfo.signedMode;

            // report service ready
            this.onReady(true);
        }
        else
        {
            //report user sign up result
            this.onSignUp(success);
        }
    }

    // user sign out
    signOut()
    {
        // set service busy
        this.onReady(false);

        // ws sign out request
        xodeWS.LogoutUser();
    }

    // sign in confirm
    onSignOutConfirm(userId)
    {
        //disconect former client
        this.disconnectWSapi();

        // set new service connection
        this.connectService();
    }

    // Get current Posts with x-ode api
    getPosts()
    {
        // ws posts request
        xodeWS.GetPosts();
    }

    // get Posts query done
    onCurrentPosts(featurePosts)
    {
        //deliver current posts
        this.onPosts(featurePosts);
    }

    // Add Post to x-ode
    addPost(featurePost)
    {
        // ws add post request
        xodeWS.AddNewPost(featurePost);
    }
    
    // add Post confirm
    onPostAdded(newPostId)
    {
        //confirm new post added
        this.onAddPostConfirm(newPostId);
    }
    
    // add Post notice
    onPostAddedNotice(newPostId)
    {
        // new post added notice
        this.onAddPostNotice(newPostId);
    }

    // Delete Post from x-ode
    deletePost(postId)
    {
        // ws delete post request
        xodeWS.DeletePost(postId);
    }

    // delete Post confirm
    onPostDeleted(postId, success)
    {
        //confirm post deleted
        this.onDeletePostConfirm(postId, success);
    }
        
    // deleted Post notice
    onPostDeletedNotice(postId)
    {
        // post deleted notice
        this.onDeletePostNotice(postId);
    }

    // Get current Post Replies with x-ode api
    getPostReplies(postId)
    {
        // ws post reply messages request
        xodeWS.GetPostReplies(postId);
    }

    // get Post Replies query done
    onRequestedPostReplies(postId, postReplies)
    {
        //deliver post current reply messages
        this.onPostReplies(postId, postReplies);
    }

    // Reply to Post
    replyToPost(newPostMessage)
    {
        // ws add post reply message request
        xodeWS.ReplyToPost(newPostMessage);
    }
    
    // add Post Reply Message confirm
    onPostReplied(postId, messageId)
    {
        // confirm new reply to post
        this.onReplyConfirm(postId, messageId);
    }
    
    // add Post Reply Message notice
    onPostRepliedNotice(postId, messageUserId, messageId)
    {
        // post replied notice
        this.onReplyNotice(postId, messageUserId, messageId);
    }

    // Get current user offline pending notices
    getPendingNotices()
    {
        // ws user offline pending notices request
        xodeWS.GetPendingNotices();
    }

    // user offline pending notices
    onUserPendingNotices(userId, pendingNotices)
    {
        // pending reply notices
        this.onPendingNotices(userId, pendingNotices);
    }

    // request post to private
    privatePost(postId)
    {
        // ws post as private request
        xodeWS.PrivatePost(postId);
    }
    
    // post to private confirm
    onPostAsPrivate(postId, success)
    {
        //confirm post as private
        this.onPrivatePostConfirm(postId, success);
    }

    // post to private notice
    onPostAsPrivateNotice(postId)
    {
        // post as private notice
        this.onPrivatePostNotice(postId);
    }

    // request post to public
    publicPost(postId)
    {
        // ws post as public request
        xodeWS.PublicPost(postId);
    }

    // post to public confirm
    onPostAsPublic(postId, success)
    {
        //confirm post as public
        this.onPublicPostConfirm(postId, success);
    }

    // post to public notice
    onPostAsPublicNotice(postId)
    {
        // post as public notice
        this.onPublicPostNotice(postId);
    }

    // request user comunity (connections)
    getUserComunity()
    {
        // ws user comunity request
        xodeWS.GetUserComunity();
    }

    // user comunity (connections)
    onUserComunityConnections(userId, userComunity)
    {
        // user comunity
        this.onUserComunity(userId, userComunity);
    }

    // set Post
    setPost(timeSubmit, lng, lat, newPostTitle, newPostContent, timeframeFrom, timeframeTo, zoom)
    {
        // new Post to set
        var postFeature =
        {
            type: 'Feature',
            geometry: 
            {
                type: 'Point',
                coordinates: [lng, lat]
            },
            properties: 
            {
                postId: 0,

                submitDateTime: timeSubmit.toJSON(),//as string to match backend serialized return
                epochSubmit: Math.round(timeSubmit.getTime()),//epoch(xms). /1000->epoch''

                creatorEnvID: xodeUser.userEnvID,
                userId: xodeUser.userId,
                claimed: xodeUser.signedMode,
                authorName: xodeUser.userName,
                isPublic: true,
                comunityConnectionType: 'self',

                title: newPostTitle,
                content: newPostContent,

                timeFrom: timeframeFrom.toJSON(),//as string to match backend serialized return
                timeTo: timeframeTo.toJSON(),//as string to match backend serialized return
                epochFrom: Math.round(timeframeFrom.getTime()),//epoch(xms). /1000->epoch''
                epochTo: Math.round(timeframeTo.getTime()),//epoch(xms). /1000->epoch''
                
                postZoom: zoom,

                replyCount: 0,//activity note. used service->app dierction
                epochLastTimeReplied: 0,//epoch(xms). /1000->epoch''//activity note. used service->app dierction
                involvedUserCount: 0,//activity note. used service->app dierction

                alt: 0
            }
        };

        return postFeature;
    }

    // set Post Reply Message
    setReplyMessage(timeSubmit, postId, newMessage)
    {
        // new Message to set
        var newPostReplyMessage = 
        {
            messageId: 0,

            submitDateTime: timeSubmit.toJSON(),//as string to match backend serialized return
            epochSubmit: Math.round(timeSubmit.getTime()),//epoch(xms). /1000->epoch''

            authorId: xodeUser.userId,
            authorName: xodeUser.userName,

            postId: postId,
        
            messageContent: newMessage
        };

        return newPostReplyMessage;
    }

    // start service availability periodically check
    StartServiceCheck()
    {
        //disable previous session (if any) running service check
        try
        {
            if (this.checkInterval)
            {
                clearInterval(this.checkInterval);
            }
        }
        catch
        {}

        //disable pending service check timeout in progress
        try
        {
            if (this.pingTimeout)
            {
                clearTimeout(this.pingTimeout);
            }
        }
        catch
        {}

        //check service periodically
        this.checkInterval = setInterval
        (
            ()=>
            {
                // ping check request
                xodeWS.sendPingCHK(); // PING_CHK <-> PING_ACK
                
                //wait for service check acknowledgement
                this.pingTimeout = setTimeout
                (
                    ()=>
                    {
                        // proceed to notify and attepmt to recover connection
                        this.ConnectionLost();
                    },
                    10000 //10 seconds margin for ack
                );
            },
            30000 //check every 30 seconds
        );
    }

    // stop service availability periodically check
    StopServiceCheck()
    {
        //disable service check
        try
        {
            if (this.checkInterval) 
            {
                clearInterval(this.checkInterval);
            }
        }
        catch
        {}

        // disable pending service check timeout in progress
        try
        {
            if (this.pingTimeout)
            {
                clearTimeout(this.pingTimeout);
            }
        }
        catch
        {}
    }

    // service ping check acknowledgement
    onPingACK(userId)
    {
        // validate ACK
        if (userId === xodeUser.userId)
        {
            // service checked available. disable pending service check timeout in progress
            try
            {
                if (this.pingTimeout)
                {
                    clearTimeout(this.pingTimeout);
                }
            }
            catch
            {}
        }
    }

    // service connection lost
    ConnectionLost()
    {
        if (this.connected)
        {
            // set connected status flag
            this.connected = false;

            // report service not ready
            this.onReady(false);

            // notify app/user service connection lost
            this.onConnectionLost();

            // attempting to close session remains
            try
            {
                xodeWS.Close();
            }
            catch
            {}
        }
        else
        {
            // attempting to reconnect on new session
            try
            {
                this.connectService();
            }
            catch
            {}
        }
    }

}

// service client instance
const xodeService = new ServiceClient();
export default xodeService;