import React from 'react';

import './MenuItem.css';

class MenuItem extends React.Component
{
  constructor(props)
  {
    super(props);

    this.itemClick = this.itemClick.bind(this);
    this.onAction = this.props.callbackAction;
  }

  itemClick()
  {
    this.onAction();
  }

  render()
  {
    return (

      <div className="MenuItem" >
        <div className={'ItemText' + (this.props.itemEnabled ? ' enabled' : ' disabled')}
          onClick={this.itemClick}
        >{this.props.text}</div>

      </div>

    );
  }
}

export default MenuItem;