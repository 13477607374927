import React from 'react';

import Button from '../Button/Button';

import './ConfirmDialog.css';

class ConfirmDialog extends React.Component
{
  constructor(props)
  {
    super(props);

    this.onConfirmDialog = this.props.callbackConfirm;
    this.onCloseDialog = this.props.callbackClose;
    this.onDoneDialog = this.props.callbackDone;

    this.onConfirm = this.onConfirm.bind(this);
    this.onAbort = this.onAbort.bind(this);
  }

  onConfirm()
  {
    this.onConfirmDialog();
    this.onDoneDialog();
  }

  onAbort()
  {
    this.onCloseDialog();
    this.onDoneDialog();
  }

  render()
  {
    return (

      <div className="ConfirmDialog">

        <div className="confirmMessage">{this.props.confirmMessage}</div>

        <Button type="close" position="right-bottom-2" callbackAction={this.onAbort} tip={'No'} alt="X" />
        <Button type="submit" position="right-bottom-3" callbackAction={this.onConfirm} tip={'Yes'} alt=">" />

      </div>

    );
  }
}

export default ConfirmDialog;