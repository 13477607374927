import React from 'react';

import './MessageThread.scss';
import Message from '../Message/Message';

class MessageThread extends React.Component
{
  constructor(props)
  {
    super(props);

    // go to post callback, from messages, to id on link
    this.goToPostCallback = this.goToPostCallback.bind(this);
  }

  // go to post callback, from messages, to id on link
  goToPostCallback(postId)
  {
    this.props.goToPostCallback(postId);
  }

  render()
  {
    return (
      <div className="MessageThread">

        <Message from="author" by={(this.props.post.properties.userId === this.props.currentUser) ? 'self' : (this.props.userComunity.includes(this.props.post.properties.userId) ? 'connected' : 'others')} 
          content={this.props.post.properties.content} author={this.props.post.properties.authorName} epochSubmit={this.props.post.properties.epochSubmit} 
          goToPostCallback={this.goToPostCallback}
        />

        { 
          this.props.replies &&
          this.props.replies.map
          ((reply)=>
            {
              return <Message from={(reply.authorId === this.props.post.properties.userId) ? 'author' : 'replier'} by={(reply.authorId === this.props.currentUser) ? 'self' : (this.props.userComunity.includes(reply.authorId) ? 'connected' : 'others')} 
                        content={reply.messageContent} author={reply.authorName} epochSubmit={reply.epochSubmit} 
                        goToPostCallback={this.goToPostCallback}
                      />;
            }
          )
        }

      </div>
    );
  }
}

export default MessageThread;