
// x-ode rest API definition
class RESTapi
{
    // x-ode rest API url
    restapiUrl;

    // constructor and property definition
    constructor(apiUrl)
    {
        this.restapiUrl = apiUrl;
    }

    // request for session connection to x-ode,
    // retrieves session info response to loginDone
    LogIn(userEnvID, logedinCallback)
    {
        let url = this.restapiUrl + 'Login?' + userEnvID;

        let req = new XMLHttpRequest();

        req.responseType = 'json';
        
        req.open('GET', url);

        req.onload = function() 
        {
            // get session info in json
            var sessionInfo = req.response;

            // invoke done method with response
            logedinCallback(sessionInfo);
        };

        req.onerror = function() 
        {
            // Error on api query

            // set failed session info in json
            /*var sessionInfo = 
            {
                userId: 0
            };
            logedinCallback(sessionInfo);*/
        };

        req.send();
    }

}

// deploy x-ode rest api url
const restapiUrl = process.env.REACT_APP_SERVICE_REST_API;

// x-ode rest api instance 
const xodeAPI = new RESTapi(restapiUrl);
export default xodeAPI;
