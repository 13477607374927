
// x-ode date and epoch(xms) utils. 1000->epoxh''
class DateEpoch
{
    // current exact date time
    currentDateTime()
    {
        var date = new Date();

        return date;
    }

    // epoch(xms) to exact date time
    epochToDateTime(epoch)
    {
        var date = new Date(0);
        date.setTime(epoch);//epoch(xms). *1000->epoch''

        return date;
    }

    // current date with optional offset
    currentDate(daysOffseet=0)
    {
        var date = new Date();
        date.setDate(date.getDate() + daysOffseet);

        var min = date.getMinutes();
        date.setMinutes(0,0,0);
        if (min > 30)
        {
            date.setHours(date.getHours() + 1);
        }

        return date;
    }

    // date to epoch(xms)
    dateToEpoch(date)
    {
        var epoch = Math.round(date.getTime());//epoch(xms). /1000->epoch(xms)
        return epoch;
    }

    // epoch(xms) to date
    epochToDate(epoch)
    {
        var date = new Date(0);
        date.setTime(epoch);//epoch(xms). *1000->epoch''

        var min = date.getMinutes();
        date.setMinutes(0,0,0);
        if (min > 30)
        {
            date.setHours(date.getHours() + 1);
        }

        return date;
    }

    // date to custom ordered string
    orderedStringDate(date)
    {
        var YYYY = date.getFullYear();
        var M = date.getMonth() + 1;
        var MM = (M<10) ? '0'+M : M;
        var D = date.getDate();
        var DD = (D<10) ? '0'+D : D;
        var H = date.getHours();
        var HH = (H<10) ? '0'+H : H;

        return YYYY + '/' + MM + '/' + DD + ' ' + HH + 'h';
    }

    // string timestams for timescale ticks,
    // according to tick hierarchy level
    timeTickInfo(epoch)
    {
        var date = new Date(0);
        date.setTime(epoch);//epoch(xms). *1000->epoch''

        const min = date.getMinutes();
        const hour = date.getHours();
        const day = date.getDate();
        const month = date.getMonth();
        const year = date.getFullYear();
        const decade = year/10.0;
        const century = year/100.0;
        const millenium = year/1000.0;

        if ((millenium%1.0 === 0) && (month === 0) && (day === 1) && (hour === 0) && (min === 0))
        {
            //millenium tick
            return(
            {
                level: 'I',
                text: year,
                datetimestring: this.orderedStringDate(date)
            });
        }

        if ((century%1.0 === 0) && (month === 0) && (day === 1) && (hour === 0) && (min === 0))
        {
            //century tick
            return(
            {
                level: 'C',
                text: year,
                datetimestring: this.orderedStringDate(date)
            });
        }

        if ((decade%1.0 === 0) && (month === 0) && (day === 1) && (hour === 0) && (min === 0))
        {
            //decade tick
            return(
            {
                level: 'X',
                text: ''+year+'',
                datetimestring: this.orderedStringDate(date)
            });
        }

        if ((month === 0) && (day === 1) && (hour === 0) && (min === 0))
        {
            //year tick
            return(
            {
                level: 'Y',
                text: ''+year+'',
                datetimestring: this.orderedStringDate(date)
            });
        }

        if ((day === 1) && (hour === 0) && (min === 0))
        {
            //month tick
            const months3 = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
            return(
            {
                level: 'M',
                text: months3[month],
                datetimestring: this.orderedStringDate(date)
            });
        }

        if ((hour === 0) && (min === 0))
        {
            //day tick
            //const months3 = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
            return(
            {
                level: 'D',
                text: day,//day+'/'+months3[month],
                datetimestring: this.orderedStringDate(date)
            });
        }

        if (min === 0)
        {
            //hour tick
            return(
            {
                level: 'H',
                text: ((hour<10) ? '0'+hour : hour)+':00',
                datetimestring: this.orderedStringDate(date)
            });
        }

        //inexact tick
        return(
        {
            level: '|',
            text: '|',
            datetimestring: this.orderedStringDate(date)
        });

    }

    // hours span on epoch timeframe
    epochHoursIn(epochFrom, epochTo)
    {
        return Math.floor((epochTo - epochFrom) / this.msHour);
    }

    // days span on epoch timeframe
    epochDaysIn(epochFrom, epochTo)
    {
        return Math.floor((epochTo - epochFrom) / this.msDay);
    }

    // months span on epoch timeframe
    epochMonthsIn(epochFrom, epochTo)
    {
        return Math.floor((epochTo - epochFrom) / this.msMonth);
    }

    // years span on epoch timeframe
    epochYearsIn(epochFrom, epochTo)
    {
        return Math.floor((epochTo - epochFrom) / this.msYear);
    }

    // decades span on epoch timeframe
    epochDecadesIn(epochFrom, epochTo)
    {
        return Math.floor((epochTo - epochFrom) / this.msDecade);
    }

    // centuries span on epoch timeframe
    epochCenturiesIn(epochFrom, epochTo)
    {
        return Math.floor((epochTo - epochFrom) / this.msCentury);
    }

    // millennia span on epoch timeframe
    epochMillenniaIn(epochFrom, epochTo)
    {
        return Math.floor((epochTo - epochFrom) / this.msMillenium);
    }

    // previous exact time in level
    prevExactTime(timeLevel, epochFrom)
    {
        let exactDate = new Date(0);
        exactDate.setTime(epochFrom);//epoch(xms). *1000->epoch''

        exactDate.setMilliseconds(0);
        exactDate.setSeconds(0);
        const min = exactDate.getMinutes();
        const hour = exactDate.getHours();
        const day = exactDate.getDate();
        const month = exactDate.getMonth();
        const year = exactDate.getFullYear();

        switch (timeLevel)
        {
            case 'H':
            {
                if (min > 0)
                {
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'D':
            {
                if ((hour > 0) || (min > 0))
                {
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'M':
            {
                if ((day > 1) || (hour > 0) || (min > 0))
                {
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'Y':
            {
                if ((month > 0) || (day > 1) || (hour > 0) || (min > 0))
                {
                    exactDate.setMonth(0);
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'X':
            {
                if ((year%10 > 0) || (month > 0) || (day > 1) || (hour > 0) || (min > 0))
                {
                    exactDate.setFullYear(year - (year%10));
                    exactDate.setMonth(0);
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'C':
            {
                if ((year%100 > 0) || (month > 0) || (day > 1) || (hour > 0) || (min > 0))
                {
                    exactDate.setFullYear(year - (year%100));
                    exactDate.setMonth(0);
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'I':
            {
                if ((year%1000 > 0) || (month > 0) || (day > 1) || (hour > 0) || (min > 0))
                {
                    exactDate.setFullYear(year - (year%1000));
                    exactDate.setMonth(0);
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;
        }

        return this.dateToEpoch(exactDate);
    }

    // next exact time in level
    nextExactTime(timeLevel, epochFrom)
    {
        let exactDate = new Date(0);
        exactDate.setTime(epochFrom);//epoch(xms). *1000->epoch''

        exactDate.setMilliseconds(0);
        exactDate.setSeconds(0);
        const min = exactDate.getMinutes();
        const hour = exactDate.getHours();
        const day = exactDate.getDate();
        const month = exactDate.getMonth();
        const year = exactDate.getFullYear();

        switch (timeLevel)
        {
            case 'H':
            {
                if (min > 0)
                {
                    exactDate.setHours(hour + 1)
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'D':
            {
                if ((hour > 0) || (min > 0))
                {
                    exactDate.setDate(day + 1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'M':
            {
                if ((day > 1) || (hour > 0) || (min > 0))
                {
                    exactDate.setMonth(month + 1);
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'Y':
            {
                if ((month > 0) || (day > 1) || (hour > 0) || (min > 0))
                {
                    exactDate.setFullYear(year + 1);
                    exactDate.setMonth(0);
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'X':
            {
                if ((year%10 > 0) || (month > 0) || (day > 1) || (hour > 0) || (min > 0))
                {
                    exactDate.setFullYear(year + (10-(year%10)));
                    exactDate.setMonth(0);
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'C':
            {
                if ((year%100 > 0) || (month > 0) || (day > 1) || (hour > 0) || (min > 0))
                {
                    exactDate.setFullYear(year + (100-(year%100)));
                    exactDate.setMonth(0);
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'I':
            {
                if ((year%1000 > 0) || (month > 0) || (day > 1) || (hour > 0) || (min > 0))
                {
                    exactDate.setFullYear(year + (1000-(year%1000)));
                    exactDate.setMonth(0);
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;
        }

        return this.dateToEpoch(exactDate);
    }

    // nearest exact time in level
    nearExactTime(timeLevel, epochTime)
    {
        let exactDate = new Date(0);
        exactDate.setTime(epochTime);//epoch(xms). *1000->epoch''

        exactDate.setMilliseconds(0);
        exactDate.setSeconds(0);
        const min = exactDate.getMinutes();
        const hour = exactDate.getHours();
        const day = exactDate.getDate();
        const month = exactDate.getMonth();
        const year = exactDate.getFullYear();

        switch (timeLevel)
        {
            case 'H':
            {
                if (min > 0)
                {
                    if (min >= 30)
                    {
                        exactDate.setHours(hour + 1);
                    }
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'D':
            {
                if ((hour > 0) || (min > 0))
                {
                    if (hour >= 12)
                    {
                        exactDate.setDate(day + 1);
                    }
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'M':
            {
                if ((day > 1) || (hour > 0) || (min > 0))
                {
                    if (day >= 15)
                    {
                        exactDate.setMonth(month + 1);
                    }
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'Y':
            {
                if ((month > 0) || (day > 1) || (hour > 0) || (min > 0))
                {
                    if ((month >= 5) && (day >=15))
                    {
                        exactDate.setFullYear(year + 1);
                    }
                    exactDate.setMonth(0);
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'X':
            {
                if ((Math.abs(year%10) > 0) || (month > 0) || (day > 1) || (hour > 0) || (min > 0))
                {
                    if (Math.abs(year%10) >= 5)
                    {
                        if (year >= 0)
                        {
                            exactDate.setFullYear(year + (10-Math.abs(year%10)));
                        }
                        else
                        {
                            exactDate.setFullYear(year - (10-Math.abs(year%10)));
                        }
                    }
                    else
                    {
                        if (year >= 0)
                        {
                            exactDate.setFullYear(year - Math.abs(year%10));
                        }
                        else
                        {
                            exactDate.setFullYear(year + Math.abs(year%10));
                        }
                    }
                    exactDate.setMonth(0);
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'C':
            {
                if ((Math.abs(year%100) > 0) || (month > 0) || (day > 1) || (hour > 0) || (min > 0))
                {
                    if (Math.abs(year%100) >= 50)
                    {
                        if (year >= 0)
                        {
                            exactDate.setFullYear(year + (100-Math.abs(year%100)));
                        }
                        else
                        {
                            exactDate.setFullYear(year - (100-Math.abs(year%100)));
                        }
                    }
                    else
                    {
                        if (year >= 0)
                        {
                            exactDate.setFullYear(year - Math.abs(year%100));
                        }
                        else
                        {
                            exactDate.setFullYear(year + Math.abs(year%100));
                        }
                    }
                    exactDate.setMonth(0);
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;

            case 'I':
            {
                if ((Math.abs(year%1000) > 0) || (month > 0) || (day > 1) || (hour > 0) || (min > 0))
                {
                    if (Math.abs(year%1000) >= 500)
                    {
                        if (year >= 0)
                        {
                            exactDate.setFullYear(year + (1000-Math.abs(year%1000)));
                        }
                        else
                        {
                            exactDate.setFullYear(year - (1000-Math.abs(year%1000)));
                        }
                    }
                    else
                    {
                        if (year >= 0)
                        {
                            exactDate.setFullYear(year - Math.abs(year%1000));
                        }
                        else
                        {
                            exactDate.setFullYear(year + Math.abs(year%1000));
                        }
                    }
                    exactDate.setMonth(0);
                    exactDate.setDate(1);
                    exactDate.setHours(0);
                    exactDate.setMinutes(0);
                }
            }
            break;
        }

        return this.dateToEpoch(exactDate);
    }

    // middle date reference in timeframe
    middleDate(dateFrom, dateTo)
    {
        var epochFrom = this.dateToEpoch(dateFrom);
        var epochTo = this.dateToEpoch(dateTo);
        var epochMid = Math.round((epochTo + epochFrom)/2);

        var dateMid = this.epochToDate(epochMid);

        return this.orderedStringDate(dateMid);
    }

    // x-ode Min date
    dateMin()
    {
        var date = new Date(-5000, 0);

        return date;
    }

    // x-ode Min epoch
    epochMin()
    {
        return this.dateToEpoch(this.dateMin());
    }

    // x-ode Min date
    dateMax()
    {
        var date = new Date(5000, 0);

        return date;
    }

    // x-ode Max epoch
    epochMax()
    {
        return this.dateToEpoch(this.dateMax());
    }

    // x-ode Min xms TimeRange span
    timeRangeMin()
    {
        return this.msHour * 3 //3hrs
    }

    // x-ode Max xms TimeRange span
    timeRangeMax()
    {
        return this.msCentury * 100 //10000yrs
    }

    // x-ode Min xms Timeframe span
    timeFrameMin()
    {
        return this.msHour * 1 //1hrs
    }

    // x-ode Max xms Timeframe span
    timeFrameMax()
    {
        return this.msCentury * 50 //5000yrs
    }

    // xms in Time divisions
    msHour = 1000*60*60;
    msDay = 1000*60*60*24;
    msMonth = 1000*60*60*24*20;//*30
    msYear = 1000*60*60*24*365;
    msDecade = 1000*60*60*24*365*10;
    msCentury = 1000*60*60*24*365*100;
    msMillenium = 1000*60*60*24*365*1000;
}

// date-epoch instance
const dateEpoch = new DateEpoch();
export default dateEpoch;
