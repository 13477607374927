import React from 'react';
import { isMobile } from "react-device-detect";
import './AboutDialog.css';

class AboutDialog extends React.Component
{
    constructor(props)
    {
        super(props);

        this.goHowTo = this.goHowTo.bind(this);
    }

    goHowTo()
    {
        this.props.showHowToCallback();
    }

    render()
    {
        return (
            <div className="about-panel">

                <div className="about-text">

                    <b>about x-ode:</b><br/><br/>

                    x as a marker<br/>
                    ode as a poem<br/>
                    a poem dropped somewhere, sometime<br/><br/>

                    about a missed connection<br/>
                    which happened yesterday<br/>
                    or a hundred years ago<br/><br/>

                    around the corner<br/>
                    or at the other end of the world<br/><br/>

                    <b>how it works:</b><br/><br/>

                    remember something that happened<br/>
                    dream up something else<br/><br/>

                    use the map to locate the place<br/>
                    use the time glider to figure out when<br/>
                    see if a someone already posted about it<br/>
                    reply to it if you feel concerned<br/>
                    or post a new message if you get there first<br/>
                    then wait and see if someone answers<br/>

                    <span onClick={this.goHowTo} style={{cursor:'help'}} >
                        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b><br/>
                        <b>tips...&nbsp;&nbsp;</b><br/>
                        <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b><br/>
                    </span>
                </div>
            </div>
        );
    }
}

export default AboutDialog;