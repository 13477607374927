import React from 'react';

import './NoticeItem.css';

class NoticeItem extends React.Component
{
  constructor(props)
  {
    super(props);

    this.noticeClick = this.noticeClick.bind(this);
    this.onGoPost = this.props.onGoPost;
  }

  noticeClick()
  {
    this.onGoPost(this.props.post);
    this.props.onDone();
  }

  render()
  {
    return (

      <div className="NoticeItem" >
        <div className="NoticeText"
          onClick={this.noticeClick}
        >{this.props.post.properties.title}</div>

      </div>

    );
  }
}

export default NoticeItem;