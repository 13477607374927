import React from 'react';

import Pointer from './Pointer/Pointer';

import './PopUp.scss';

class PopUp extends React.Component
{
  render()
  {
    const balloon_arrow = this.props.isBalloon ? 'balloon' : this.props.anchor;

    return (
      <div className={'PopUp '+balloon_arrow} >
        <Pointer anchor={this.props.anchor} />
        <div className="DataArea">
          {/* proper post data dialog create/show/edit component */}
          {this.props.postComponent}
        </div>
      </div>
    );
    
  }
}

export default PopUp;