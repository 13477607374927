import React from 'react';

import './Pointer.scss';

class Pointer extends React.Component
{
  render()
  {
    return (
      <div className={'PointerPlace '+this.props.anchor} >
        <svg viewBox="0 0 82 140" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="75" y="6" width="7" height="128" fill="#fff" />
          <path d="M11.92 69.88L75.84 5.96001L75.86 133.81L11.92 69.88Z" fill="white" />
          <path d="M11.92 69.88L75.84 5.96002V-5.95999L0 69.88L75.86 145.74V133.81L11.92 69.88Z" fill="black" />
        </svg>
      </div>
    )
  }
}

export default Pointer;