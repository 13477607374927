import React from 'react';

import Button from '../Button/Button';
import { isMobile } from "react-device-detect";
import './HowToPopUp.scss';

class HowToPopUp extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      currentSlide: 0,
    };

    this.getSlide = this.getSlide.bind(this);
    this.onHowToDone = this.props.callbackDone;
    this.OnClose = this.OnClose.bind(this);
    this.OnNext = this.OnNext.bind(this);
    this.OnPrevious = this.OnPrevious.bind(this);
    // Swipe.
    this.startSwiping = this.startSwiping.bind(this);
    this.stopSwiping = this.stopSwiping.bind(this);
    this.mouseMove = this.mouseMove.bind(this);
    this.swiping = false;
    // Prevents multiple swipes on same event.
    this.swiped = true;
    this.swipeX = 0;

    // Import all images from howTo folder, they will be on alphabetical order.
    const importAll = (r) => {
      return r.keys().map(r);
    }
    var images = isMobile ?
      importAll(require.context('../../img/howTo/mobile/', false, /\.(gif)$/)) :
      importAll(require.context('../../img/howTo/', false, /\.(gif)$/));

    this.slides = [];
    var subTitles = [];
    var titles = [];
    if (isMobile) {
      subTitles = [
        'Move the time line to the past & future...',
        '...zoom in & out to widen or narrow the time frame...',
        'Zoom in & click on the map then set the time frame...',
        '...write the title & message then submit',
        'Click on existing markers to reply',
        'Sign up for every new session...',
        '...and get notifications when you get replies',
      ];
      titles = [
        'How to use the timeglider :',
        'How to use the timeglider :',
        'How to create a new post :',
        'How to create a new post :',
        'How to reply to an existing post :',
        'Sign up to keep the same username :',
        'Sign up to keep the same username :',
      ];
    }
    else {
      subTitles = [
        'Move the time line to the past & future...',
        '...zoom in & out to widen or narrow the time frame...',
        '...drag the brackets to adjust the time frame',
        'Zoom in & click on the map then set the time frame…',
        '...write the title & message then submit',
        'Click on existing markers to reply',
        'Sign up for every new session...',
        '...and get notifications when you get replies',
      ];
      titles = [
        'How to use the timeglider :',
        'How to use the timeglider :',
        'How to use the timeglider :',
        'How to create a new post :',
        'How to create a new post :',
        'How to reply to an existing post :',
        'Sign up to keep the same username :',
        'Sign up to keep the same username :',
      ];
    }

    // Build the slides. First one is text only.
    this.slides.push(
      <div className="HowToSlide textOnly">
        <p class="how-to-about"><b>x-ode</b> makes it possible for anyone to post a message at specific spacetime coordinates.</p>
        <p class="how-to-about">post about a missed connection, a future encounter or about whatever you want.</p>
        <p class="how-to-about">then wait and see if someone answers…</p>
      </div>
    );
    const buildSlide = (title, subtitle, image) => {
      return (
        <div className="HowToSlide">
          <div className="title">{ title }</div>
          <div className="subtitle">{ subtitle }</div>
          <div className="image"><img src={ image } /></div>
        </div>
      );
    }
    for (let i = 0; i < images.length; i++) {
      this.slides.push(buildSlide(titles[i], subTitles[i], images[i]))
    }

  }

  OnClose()
  {
    this.onHowToDone();
  }

  OnNext()
  {
    let new_state = (this.state.currentSlide + 1);
    if (new_state >= this.slides.length) {
      new_state = this.slides.length - 1;
    }
    this.setState({
      currentSlide: new_state,
    });
  }

  OnPrevious()
  {
    let new_state = (this.state.currentSlide - 1);
    if (new_state < 0) {
      new_state = 0;
    }
    this.setState({
      currentSlide: new_state,
    });
  }

  getSlide()
  {
    return this.slides[this.state.currentSlide];
  }

  startSwiping(e)
  {
    let swipingX = (e.touches != undefined) ? e.touches[0].clientX : e.clientX;
    this.swiping = true;
    this.swipeX = swipingX;
  }

  stopSwiping() {
    this.swiped = true;
    this.swiping = false;
  }

  mouseMove(e)
  {
    if (this.swiping && this.swiped)
    {
      let swipingX = (e.touches != undefined) ? e.touches[0].clientX : e.clientX;
      let swipeDX = swipingX - this.swipeX;
      this.swipeX = swipingX;
      if (swipeDX < -10) {
        this.OnNext();
        this.swiped = false;
      }
      else if (swipeDX > 10) {
        this.OnPrevious();
        this.swiped = false;
      }
    }
  }

  render()
  {
    return (
      <div className="HowToPopUp"
        onMouseDown={this.startSwiping}
        onMouseMove={this.mouseMove}
        onMouseLeave={this.stopSwiping}
        onMouseUp={this.stopSwiping}
        onTouchStart={this.startSwiping}
        onTouchMove={this.mouseMove}
        onTouchCancel={this.stopSwiping}
        onTouchEnd={this.stopSwiping}
      >
        { this.getSlide() }
        <Button type="close" position="top-right-1" callbackAction={this.OnClose} tip={'Close'} alt="X" />
        {(this.state.currentSlide < this.slides.length - 1) && <Button type="submit" position={ isMobile ? 'right-bottom-2' : 'middle-right' } callbackAction={this.OnNext} tip={'Next'} alt=">" /> }
        {(this.state.currentSlide > 0) && <Button type="submit-back" position={ isMobile ? 'left-bottom-2' : 'middle-left' } callbackAction={this.OnPrevious} tip={'Previous'} alt="<" /> }
      </div>
    );
  }
}

export default HowToPopUp;
