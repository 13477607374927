import React from 'react';

import './Marker.css';

class Marker extends React.Component
{
  render()
  {
    return (
      <div className="Marker">
        <img src={'/img/pin.png'} className="pinimg" alt="X" />
      </div>
    );
  }
}

export default Marker;