import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { isMobile } from "react-device-detect";

import OptionsMenu from '../../../components/OptionsMenu/OptionsMenu';

import loginButtonHovered from '../../../img/loginButton/login-btn-hovered.svg';
import loginButtonNormal from '../../../img/loginButton/login-btn-normal.svg';
import '../TopButtons.scss';

class LoginBtn extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            menuOpen: false,

            hovered: false
        }

        this.buttonEnter = this.buttonEnter.bind(this);
        this.buttonLeave = this.buttonLeave.bind(this);
        this.buttonClick = this.buttonClick.bind(this);

        this.onSignIn = this.onSignIn.bind(this);
        this.onSignUp = this.onSignUp.bind(this);
        this.cancelSigningDialog = this.cancelSigningDialog.bind(this);
    }

    buttonEnter()
    {
        this.setState(
        {
            hovered: true
        });
    }

    buttonLeave()
    {
        if (!this.state.menuOpen)
        {
            this.setState(
            {
                hovered: false
            });
        }
    }

    buttonClick()
    {
        this.setState(
        {
            menuOpen: !this.state.menuOpen
        });
    }

    onSignIn()
    {
        this.setState(
        {
            menuOpen: false,
            hovered: false
        });

        //deploy sign in dialog
        this.props.signInDialog(this.cancelSigningDialog);
    }

    onSignUp()
    {
        this.setState(
        {
            menuOpen: false,
            hovered: false
        });

        //deploy sign up dialog
        this.props.signUpDialog(this.cancelSigningDialog);
    }

    cancelSigningDialog()
    {
        //keep current state
    }

    renderMenu()
    {
      let menuOptions =
      [
        {
          text: 'Sign in',
          callback: this.onSignIn,
          enabled: true
        },
        {
          text: 'Sign up',
          callback: this.onSignUp,
          enabled: true
        }
      ];

      return (
        <OptionsMenu options={menuOptions} place={'topbutton'} />
      );
    }

    render()
    {
        return (

            <div className="navbar-button"
                onMouseEnter={this.buttonEnter}
                onMouseLeave={this.buttonLeave}
                onClick={this.buttonClick}
            >
                <Tippy content="Sign in" disabled={isMobile} >
                    <img src=
                        {
                            (this.state.hovered)
                            ? loginButtonHovered
                            : loginButtonNormal
                        }
                        alt="@"
                    />
                </Tippy>

                {this.state.menuOpen && this.renderMenu()}

            </div>
        );
    }
}

export default LoginBtn;


