import React from 'react';

import './TimeTipPointer.css';

class TimeTipPointer extends React.Component 
{
  render()
  {
    return (
      <div className={'TipPointerPlace '+this.props.anchor} >
        <div className={'TipPointer '+this.props.anchor} />
      </div>
    )
  }
}

export default TimeTipPointer;