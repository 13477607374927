import React from 'react';

import Button from '../Button/Button';

import './SignInDialog.scss';

class SignInDialog extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      Username: 'Username or Email',
      UsernameValid: false,

      Password: 'password',
      PasswordValid: false,

      changedUsername: false,
      changedPassword: false
    };

    this.OnUsernameChanged = this.OnUsernameChanged.bind(this);
    this.OnPasswordChanged = this.OnPasswordChanged.bind(this);
    this.OnUsernameFocus = this.OnUsernameFocus.bind(this);
    this.OnPasswordFocus = this.OnPasswordFocus.bind(this);

    this.OnCancel = this.OnCancel.bind(this);
    this.OnSubmit = this.OnSubmit.bind(this);

    this.OnCancelConfirm = this.OnCancelConfirm.bind(this);
    this.OnCancelClose = this.OnCancelClose.bind(this);
  }

  doneSignInDialog()
  {
    this.props.callbackDone();

    this.setState(
    {
      Username: 'Username or Email',
      UsernameValid: false,

      Password: 'password',
      PasswordValid: false,

      changedUsername: false,
      changedPassword: false
    });
  }

  OnUsernameChanged(e)
  {
    let newUsername = (e.target.value === null || e.target.value === undefined || e.target.value === ' ') ? '' : e.target.value;

    this.setState(
    {
      Username: newUsername,
      UsernameValid: (newUsername !== ''),

      changedUsername: true
    });
  }

  OnUsernameFocus(e)
  {
    if (!this.state.changedUsername || !this.state.UsernameValid)
    {
      let newUsername = '';
      e.target.value = newUsername;

      this.setState(
      {
        Username: newUsername,
        UsernameValid: false,
        changedUsername: true
      });
    }
  }

  OnPasswordChanged(e)
  {
    let newPassword = (e.target.value === null || e.target.value === undefined || e.target.value === ' ') ? '' : e.target.value;

    this.setState(
    {
      Password: newPassword,
      PasswordValid: (newPassword !== ''),

      changedPassword: true
    });
  }

  OnPasswordFocus(e)
  {
    if (!this.state.changedPassword || !this.state.PasswordValid)
    {
      let newPassword = '';
      e.target.value = newPassword;
      this.setState(
      {
        Password: newPassword,
        PasswordValid: false,
        changedPassword: true
      });
    }
  }

  OnSubmit()
  {
    if (this.state.UsernameValid && this.state.PasswordValid)
    {
      // sttepmt to sign user in
      this.props.onDoSignIn(this.state.Username, this.state.Password);

      this.doneSignInDialog();
    }
    else
    {
      if (!this.state.UsernameValid)
      {
        this.setState(
        {
          changedUsername: true,
          Username: 'Username or Email'
        });
      }

      if (!this.state.PasswordValid)
      {
        this.setState(
        {
          changedPassword: true,
          Password: 'password'
        });
      }

    }
  }

  OnCancel()
  {
    if ((this.state.changedUsername && this.state.UsernameValid) || (this.state.changedPassword && this.state.PasswordValid))
    {
      this.props.confirmDialog("Do you really want to cancel sign in?", this.OnCancelConfirm, this.OnCancelClose);
    }
    else
    {
      this.props.callbackCancel()
      this.doneSignInDialog();
    }
  }

  OnCancelConfirm()
  {
    this.props.callbackCancel();
    this.doneSignInDialog();
  }

  OnCancelClose()
  {
    // keep on state
  }

  render()
  {
    return (

      <div className="SignInDialog" >

        <div className="SignInFormItem">
          <input
            className="SignInInput"
            type="text"

            value={this.state.Username}
            spellCheck="false"

            style={{color: this.state.UsernameValid ? 'black' : (this.state.changedUsername ? 'red' : 'gray')}}

            onChange={this.OnUsernameChanged}
            onFocus={this.OnUsernameFocus}
            onClick={this.OnUsernameFocus}
          />
        </div>

        <div className="SignInFormItem">
          <input
            className="SignInInput"
            type={this.state.PasswordValid ? 'password' : 'text'}

            value={this.state.Password}

            style={{color: this.state.PasswordValid ? 'black' : (this.state.changedPassword ? 'red' : 'gray')}}

            onChange={this.OnPasswordChanged}
            onFocus={this.OnPasswordFocus}
            onClick={this.OnPasswordFocus}
          />
        </div>

        <Button type="close" position="top-right-1" callbackAction={this.OnCancel} tip={'Cancel'} alt="X" />
        <Button type="submit" position="Button--next right-bottom-2" callbackAction={this.OnSubmit} tip={'Sign in'} alt=">" />

      </div>

    );
  }
}

export default SignInDialog;
