import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { isMobile } from "react-device-detect";

import NoticeItem from './NoticeItem/NoticeItem';

import noticeButtonHovered from '../../../img/noticeButton/notice-btn-hovered.svg';
import noticeButtonNormal from '../../../img/noticeButton/notice-btn-normal.svg';
import '../TopButtons.scss';
import './NoticeBtn.scss';

class NoticeBtn extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            showNotifications: false,

            hovered: false
        }

        this.buttonEnter = this.buttonEnter.bind(this);
        this.buttonLeave = this.buttonLeave.bind(this);
        this.buttonClick = this.buttonClick.bind(this);
        this.noticeDone = this.noticeDone.bind(this);
    }

    buttonEnter()
    {
        this.setState(
        {
            hovered: true,
        });
    }

    buttonLeave()
    {
        if (!this.state.showNotifications)
        {
            this.setState(
            {
                hovered: false
            });
        }
    }

    buttonClick()
    {
        if ((this.props.currentNotifications) && (this.props.currentNotifications.length > 0))
        {
            this.setState(
            {
                showNotifications: !this.state.showNotifications
            });
        }
    }

    noticeDone()
    {
        this.setState(
        {
            showNotifications: false,
            hovered: false
        });
    }

    render()
    {
        return (

            <div className="navbar-button"
                onMouseEnter={this.buttonEnter}
                onMouseLeave={this.buttonLeave}
                onClick={this.buttonClick}
            >
                <Tippy content="Message notifications" disabled={isMobile}>
                    <img src=
                        {
                            (this.state.hovered)
                            ? noticeButtonHovered
                            : noticeButtonNormal
                        }
                        alt="*"
                    />
                </Tippy>

                {
                    ((this.props.currentNotifications) && (this.props.currentNotifications.length > 0))
                    &&
                    <div className="Indicator" >
                        <div className="Number" >{this.props.currentNotifications.length}</div>
                    </div>
                }

                {this.state.showNotifications &&

                    <div className="NoticeList" >
                    {
                        this.props.currentNotifications &&
                        this.props.currentNotifications.map
                        ((notice, i)=>
                            {return(

                                <div className="NoticeSection">
                                    <NoticeItem post={notice} onGoPost={this.props.onGoPost} onDone={this.noticeDone} />
                                    {(i<this.props.currentNotifications.length-1) && <div className="NoticeSeparator" />}
                                </div>

                            );}
                        )
                    }

                    </div>
                }

            </div>
        );
    }
}

export default NoticeBtn;


