import React from 'react';

import InfoBtn from './InfoBtn/InfoBtn';
import HomeBtn from './HomeBtn/HomeBtn';
import NoticeBtn from './NoticeBtn/NoticeBtn';
import LoginBtn from './LoginBtn/LoginBtn';
import SignedBtn from './SignedBtn/SignedBtn';

import './TopButtons.scss';

class TopButtons extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state=
    {
      signedTest: false
    }
  }

  render()
  {
    return(

      <div className="TopBar" onMouseDown={(e) => {e.preventDefault();}} >

        <div className="navbar-left" >

          <InfoBtn showHowToCallback={this.props.showHowToCallback}/>

        </div>

        <div className="navbar-right">

          <NoticeBtn
            currentNotifications={this.props.currentNotifications}
            onGoPost={this.props.onGoPost}
          />

          <HomeBtn
            onGoHome={this.props.onGoHome}
          />

          {
            (this.props.signedMode)
            ?
              <SignedBtn
                userId={this.props.userId}
                userName={this.props.userName}

                onSignOut={this.props.onSignOut}
                confirmDialog={this.props.confirmDialog}
              />
            :
              <LoginBtn
                userId={this.props.userId}
                userName={this.props.userName}
                signedMode={this.props.signedMode}

                signInDialog={this.props.signInDialog}
                signUpDialog={this.props.signUpDialog}
              />
          }

        </div>
        
      </div>
    );
  }
}

export default TopButtons;