import React from 'react';

import MenuItem from './MenuItem/MenuItem';

import './OptionsMenu.scss';

class OptionsMenu extends React.Component
{
  render()
  {
    return (

      <div className={'OptionsMenu '+this.props.place}>

        { 
          this.props.options &&
          this.props.options.map
          ((item, i)=>
            {
              return(
                <div className="ItemSection">
                  <MenuItem text={item.text} callbackAction={item.callback} itemEnabled={item.enabled} />
                  {(i<this.props.options.length-1) && <div className="ItemSeparator" />}
                </div>
              );
            }
          )
        }

      </div>

    );
  }
}

export default OptionsMenu;