import React from 'react';

import dateEpoch from '../../../serviceclient/dateEpoch';
import TimeFrameLabel from './TimeFrameLabel/TimeFrameLabel';
import { isMobile } from "react-device-detect";

import './TimeFrame.scss';

class TimeFrame extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      width: 0,
      dragging: false
    };

    this.timeFrom = this.props.timeFrame.timeFrom; // TimeFrame datetime from bracket
    this.timeTo = this.props.timeFrame.timeTo; // TimeFrame datetime to bracket

    this.frameFrom = 0; // TimeFrame pixels from bracket
    this.frameTo = 0; // TimeFrame pixels from bracket

    this.leftWidth = 0; // pixels left to TimeFrame
    this.rightWidth = 0; // pixels right to TimeFrame

    this.fittedTimeFrame = false;

    this.TimeFrameSet = this.props.callbackTimeFraming;

    this.timeframeUpdated = this.timeframeUpdated.bind(this);
    this.props.setTimeFrameChanged(this.timeframeUpdated);

    this.timeFromFallback = null; // Fallback TimeFrame datetime from bracket
    this.timeToFallback = null; // Fallback TimeFrame datetime to bracket

    this.leftBracketMouseDown = this.leftBracketMouseDown.bind(this);
    this.leftBracketTouchStart = this.leftBracketTouchStart.bind(this);

    this.rightBracketMouseDown = this.rightBracketMouseDown.bind(this);
    this.rightBracketTouchStart = this.rightBracketTouchStart.bind(this);

    this.bracketTouchMove = this.bracketTouchMove.bind(this);
    this.bracketMouseMove = this.bracketMouseMove.bind(this);
    this.stopDragging = this.stopDragging.bind(this);
    this.fixBracketPosition = this.fixBracketPosition.bind(this);

    this.leftDrag = false; // Is the user dragging the bracket?
    this.leftDragXo = 0; // Bracket initial/last position (px)
    this.leftDragX = null; // Actual bracket position in the browser. (px)

    this.rightDrag = false;
    this.rightDragXo = 0;
    this.rightDragX = null;

    this.timer = null;
  }

  componentDidMount()
  {
    this.setState(
    {
      width: this.container.parentElement.getBoundingClientRect().width
    });
    window.addEventListener('resize', this.resizedTimeFrame.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    this.timeFrom = this.props.timeFrame.timeFrom;
    this.timeTo = this.props.timeFrame.timeTo;

    if (prevState.width !== this.state.width) {
      this.calcTimeFrame();
    }
  }

  // event listener to get new width on resize
  resizedTimeFrame()
  {
    this.setState(
    {
      width: window.innerWidth
    });
  }

  // Brackets interaction handlers
  bracketMouseMove(e) {
    let X = e.clientX;
    if (this.leftDrag)
    {
      this.moveLeftBracket(X, true);
    }
    else if (this.rightDrag)
    {
      this.moveRightBracket(X, true);
    }
  }

  bracketTouchMove(e)
  {
    let X = e.touches[0].clientX;
    if (this.leftDrag)
    {
      this.moveLeftBracket(X, true);
    }
    else if (this.rightDrag)
    {
      this.moveRightBracket(X, true);
    }
  }

  stopDragging()
  {
    this.leftDrag = false;
    this.rightDrag = false;
    this.setState({ dragging: false });
  }

  //left bracket
  leftBracketMouseDown(e)
  {
    this.leftDragXo = e.clientX;
    this.leftDrag = true;
    this.setState({ dragging: true });
  }

  leftBracketTouchStart(e)
  {
    this.leftDragXo = e.touches[0].clientX;
    this.leftDrag = true;
  }

  moveLeftBracket(X, user_triggered = false)
  {
    let DX = X - this.leftDragXo;
    if (DX === 0 && user_triggered) {
      return;
    }

    let frameFrom = X;

    let epochRangeFrom = dateEpoch.dateToEpoch(this.props.timeRange.timeFrom);
    let epochRangeTo = dateEpoch.dateToEpoch(this.props.timeRange.timeTo);
    let rangeSpan = epochRangeTo - epochRangeFrom;

    const limitfactor = 10;
    let limitWidth = this.state.width/limitfactor;

    let minTimeWidth = (dateEpoch.timeFrameMin() * this.state.width) / rangeSpan;
    let limitFrom = this.frameTo - ((minTimeWidth > limitWidth) ? minTimeWidth : limitWidth);

    // Limit the brackets.
    if (frameFrom < limitWidth) {
      frameFrom = limitWidth;
    }
    else if (frameFrom > limitFrom) {
      frameFrom = limitFrom;
    }

    let epochFrom = epochRangeFrom + (frameFrom * rangeSpan) / this.state.width;
    epochFrom = (DX > 0) ? dateEpoch.nextExactTime('H', epochFrom) : dateEpoch.prevExactTime('H', epochFrom);
    if (epochFrom <= epochRangeFrom) { epochFrom += dateEpoch.msHour };

    frameFrom = ((epochFrom - epochRangeFrom) * this.state.width) / rangeSpan;

    this.timeFrom = dateEpoch.epochToDate(epochFrom);
    this.frameFrom = frameFrom;
    this.leftWidth = this.frameFrom;

    this.leftDragXo = X;
    if (user_triggered) {
      this.leftDragX = frameFrom;
    }

    this.TimeFrameSet(this.timeFrom, this.timeTo);
  }

  //right bracket
  rightBracketMouseDown(e)
  {
    this.rightDragXo = e.clientX;
    this.rightDrag = true;
    this.setState({ dragging: true });
  }

  rightBracketTouchStart(e)
  {
    this.rightDragXo = e.touches[0].clientX;
    this.rightDrag = true;
  }

  moveRightBracket(X, user_triggered = false)
  {
    let DX = X - this.rightDragXo;
    if (DX === 0 && user_triggered) {
      return;
    }

    let frameTo = X;

    let epochRangeFrom = dateEpoch.dateToEpoch(this.props.timeRange.timeFrom);
    let epochRangeTo = dateEpoch.dateToEpoch(this.props.timeRange.timeTo);
    let rangeSpan = epochRangeTo - epochRangeFrom;

    const limitfactor = 10;
    let limitWidth = this.state.width/limitfactor;

    let minTimeWidth = (dateEpoch.timeFrameMin() * this.state.width) / rangeSpan;
    let limitTo = this.frameFrom + ((minTimeWidth > limitWidth) ? minTimeWidth : limitWidth);

    // Limit the brackets.
    if (frameTo > (this.state.width-limitWidth)) {
      frameTo = (this.state.width-limitWidth);
    }
    else if (frameTo < limitTo) {
      frameTo = limitTo;
    }

    let epochTo = epochRangeFrom + (frameTo * rangeSpan) / this.state.width;
    epochTo = (DX > 0) ? dateEpoch.nextExactTime('H', epochTo) : dateEpoch.prevExactTime('H', epochTo);
    if (epochTo >= epochRangeTo) { epochTo -= dateEpoch.msHour };

    frameTo = ((epochTo - epochRangeFrom) * this.state.width) / rangeSpan;

    this.timeTo = dateEpoch.epochToDate(epochTo);
    this.frameTo = frameTo;
    this.rightWidth = this.state.width - this.frameTo;

    this.rightDragXo = X;
    if (user_triggered) {
      this.rightDragX = frameTo;
    }

    this.TimeFrameSet(this.timeFrom, this.timeTo);
  }

  // Position the brackets according to the dates.
  fixBracketPosition() {
    // Time range in the window.
    let epochRangeFrom = dateEpoch.dateToEpoch(this.props.timeRange.timeFrom);
    let epochRangeTo = dateEpoch.dateToEpoch(this.props.timeRange.timeTo);

    // Time range from bracket to bracket.
    let epochFrameFrom = dateEpoch.dateToEpoch(this.props.timeFrame.timeFrom);
    let epochFrameTo = dateEpoch.dateToEpoch(this.props.timeFrame.timeTo);

    // Total date range in the window.
    let rangeSpan = epochRangeTo - epochRangeFrom;
    this.frameFrom = ((epochFrameFrom - epochRangeFrom) * this.state.width) / rangeSpan;
    this.frameTo = ((epochFrameTo - epochRangeFrom) * this.state.width) / rangeSpan;

    this.leftWidth = this.frameFrom;
    this.rightWidth = this.state.width - this.frameTo;
    this.leftDragX = this.frameFrom;
    this.rightDragX = this.frameTo;
    this.forceUpdate();
  }

  // This function is only called when the timeframe changes, on dragging the
  // timeframe or zooming in or out. Resizing the browser doesn't trigger this
  // function.
  timeframeUpdated()
  {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      // trigger time frame changed
      if (this.fittedTimeFrame)
      {
        if (!this.timeFrom || !this.timeTo)
        {
          this.timeFrom = this.timeFromFallback;
          this.timeTo = this.timeToFallback;
        }

        this.TimeFrameSet(this.timeFrom, this.timeTo);

        this.timeFromFallback = this.timeFrom;
        this.timeToFallback = this.timeTo;
      }

      this.moveLeftBracket(this.leftDragX);
      this.moveRightBracket(this.rightDragX);
    }, 100);

  }

  // set TimeFrame values and current representation
  calcTimeFrame()
  {
    //calculate TimeFame
    // Time range in the window.
    let epochRangeFrom = dateEpoch.dateToEpoch(this.props.timeRange.timeFrom);
    let epochRangeTo = dateEpoch.dateToEpoch(this.props.timeRange.timeTo);

    // Time range from bracket to bracket.
    let epochFrameFrom = dateEpoch.dateToEpoch(this.props.timeFrame.timeFrom);
    let epochFrameTo = dateEpoch.dateToEpoch(this.props.timeFrame.timeTo);

    // Total date range in the window.
    let rangeSpan = epochRangeTo - epochRangeFrom;
    let frameFrom = ((epochFrameFrom - epochRangeFrom) * this.state.width) / rangeSpan;
    let frameTo = ((epochFrameTo - epochRangeFrom) * this.state.width) / rangeSpan;

    // Check TimeFrame fit to TimeRange, position the brackets inside the
    // minimum margins. If it was set this way, then there is a update later.
    const limitfactor = 10;
    let limitWidth = this.state.width/limitfactor;
    if (frameFrom < limitWidth) {
      frameFrom = limitWidth;
    }
    if ((this.state.width - frameTo) < limitWidth) {
      frameTo = this.state.width - limitWidth;
    }
    this.frameFrom = frameFrom;
    this.frameTo = frameTo;
    this.leftDragX = this.frameFrom;
    this.rightDragX = this.frameTo;
    this.leftWidth = this.frameFrom;
    this.rightWidth = this.state.width - this.frameTo;
    // Since none of these values changed are states it will not render after
    // updating them.
    this.forceUpdate();
  }

  render()
  {
    // intermediate render to setup ref to access and get width
    return (
      <div ref={el => (this.container = el)} >
        {this.state.width && this.renderTimeFrame()}
      </div>
    );
  }

  renderTimeFrame()
  {
    return (

      <div className={`TimeFrame ${this.state.dragging ? "dragging" : ""}`}
        onTouchCancel={this.stopDragging}
        onTouchEnd={this.stopDragging}
        onMouseLeave={this.stopDragging}
        onMouseUp={this.stopDragging}
        onMouseMove={this.bracketMouseMove}
        onTouchMove={this.bracketTouchMove}
      >

        <div className="TimeFrameLeft" style={{ width: (this.leftWidth)+'px'}} >

          {!isMobile &&
            <img src={'/img/bracket-arrow-left.svg'} className="LeftArrow" alt="<" onMouseDown={(e) => { e.preventDefault(); }} />
          }
          <TimeFrameLabel className={`From${this.leftWidth < 100 ? " innerLabel" : ''}`} time={this.props.timeFrame.timeFrom} onClick={this.props.timeLabelClicked} />

        </div>

        <div className="TimeFrameBrackets">
          <img src={'/img/bracket-red-left.svg'} className="LeftBracket" alt="[" onMouseDown={(e) => {e.preventDefault();}} />
          <img src={'/img/bracket-red-right.svg'} className="RightBracket" alt="[" onMouseDown={(e) => {e.preventDefault();}} />
        </div>

        <div className="TimeFrameRight" style={{ width: this.rightWidth +'px'}} >

          {!isMobile &&
            <img src={'/img/bracket-arrow-right.svg'} className="RightArrow" alt="<" onMouseDown={(e) => { e.preventDefault(); }} />
          }
          <TimeFrameLabel className={`To${this.rightWidth < 100 ? " innerLabel": '' }`} time={this.props.timeFrame.timeTo} onClick={this.props.timeLabelClicked} />

        </div>

        <div className="bracketHandler" style={{ left: (this.frameFrom - 30) + 'px' }} onMouseDown={this.leftBracketMouseDown} />
        <div className="bracketHandler" style={{ left: (this.frameTo - 30) + 'px' }} onMouseDown={this.rightBracketMouseDown} />

      </div>

    );
  }
}

export default TimeFrame;
