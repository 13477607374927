import React from 'react';

import TimeTipPointer from './TimeTipPointer/TimeTipPointer';

import './TimeTip.css';

class TimeTip extends React.Component
{
  render()
  {
    return (
      <div className={'Tip '+this.props.anchor} >
        <TimeTipPointer anchor={this.props.anchor} />
        <div className="TextArea">
          {this.props.text}
        </div>        
      </div>
    );
  }
}

export default TimeTip;