import React from 'react';

import dateEpoch from '../../../../serviceclient/dateEpoch';

import './PostTitleInfo.scss';

class PostTitleInfo extends React.Component
{
  render()
  {
    let timeFrom = dateEpoch.epochToDate(this.props.epochFrom);
    let timeTo = dateEpoch.epochToDate(this.props.epochTo);
    let timeFrameText = '[ ' + dateEpoch.orderedStringDate(timeFrom) + ' - ' + dateEpoch.orderedStringDate(timeTo) + ' ]';

    return (
      <div className="PostTitleInfo">
        <div className="InfoArea">
          <div className="PostTitle">{this.props.title}</div>
          <div className="TimeFrameNotice">{timeFrameText}</div>
        </div>
      </div>
    );
  }
}

export default PostTitleInfo;