import React from 'react';

import TimeTip from '../../../TimeTip/TimeTip';

import './TimeTick.scss';

class TimeTick extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      showTip: false
    }

    this.tipTime = '';
    this.tickEnter = this.tickEnter.bind(this);
    this.tickLeave = this.tickLeave.bind(this);
  }

  tickEnter()
  {
    // Commenting out to remove the Tip functionality for now.
    /*this.tipTime = this.props.timeInfo.datetimestring;

    this.setState(
    {
      showTip: true
    });*/
  }

  tickLeave()
  {
    /*this.setState(
    {
      showTip: false
    });

    this.tipTime = '';*/
  }

  render()
  {
    return (
      <div className={'TimeTick '+this.props.timeInfo.level} style={{left: this.props.timeInfo.position}} onMouseEnter={this.tickEnter} onMouseLeave={this.tickLeave} >
        <div className={'timeStamp '+this.props.classType+'Label '+this.props.timeInfo.level} >
          { this.props.timeInfo.text }
        </div>

        {this.state.showTip ? <TimeTip anchor="bottom" text={this.tipTime} /> : null }

      </div>
    );
  }
}

export default TimeTick;
