import React from 'react';
import ReactDOM from 'react-dom';

import Marker from './Marker/Marker';

import './PreMarker.css';

//Mapbox SDK
import mapboxgl from 'mapbox-gl';

class PreMarker extends React.Component
{
    constructor(props)
    {
        super(props);

        //map marker Container
        this.markerNode = document.createElement('div');

        //map marker
        this.marker = null;
    }

    // pre update cleanup init
    componentWillUpdate()
    {
        if (this.marker)
        {
            this.marker.remove();
        }
    }

    // setup updated
    componentDidUpdate()
    {
        if (this.props.location)
        {
            // set mapbox sdk marker instance
            this.marker = new mapboxgl.Marker(this.markerNode);

            // render marker
            ReactDOM.render(<Marker />, this.markerNode);

            // set marker on map
            this.marker.setLngLat(this.props.location).addTo(this.props.map);
        }
    }

    componentWillUnmount()
    {
        // release previous marker instance
        return () => this.marker.remove();
    }

    render()
    {
        return (
            this.props.location
                ? <div className="PreMarker"/>
                : null
        );
    }
}

export default PreMarker;