import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import {isMobile} from "react-device-detect";

import Button from '../../Button/Button';

import './DateTimeDialog.scss';

class DateTimeDialog extends React.Component
{
  constructor(props)
  {
    super(props);

    this.dateFrom = this.props.dateFrom;
    this.dateTo = this.props.dateTo;

    this.DatesChanged = this.props.callbackDatesChanged;
    this.onCloseDialog = this.props.callbackCloseDialog;

    this.onDateFromChange = this.onDateFromChange.bind(this);
    this.onDateToChange = this.onDateToChange.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  onDateFromChange(newFromDate)
  {
    this.dateFrom = newFromDate;

    if (this.dateFrom >= this.dateTo)
    {
      this.dateFrom = new Date(this.dateTo);
      this.dateFrom.setHours(this.dateFrom.getHours() - 1);
    }

    this.DatesChanged(this.dateFrom, this.dateTo);
    this.forceUpdate();
  }

  onDateToChange(newToDate)
  {
    this.dateTo = newToDate;

    if (this.dateTo <= this.dateFrom)
    {
      this.dateTo = new Date(this.dateFrom);
      this.dateTo.setHours(this.dateTo.getHours() + 1);
    }

    this.DatesChanged(this.dateFrom, this.dateTo);
    this.forceUpdate();
  }

  onClose()
  {
    this.onCloseDialog();
  }

  render()
  {
    return (

      <div className="DateTimeDialog">

        <div className="ContentArea" >

          <div className="bracket">
            <img src={'/img/bracket-post-left.svg'} className="bracketimg" alt="[" />
          </div>

          <DateTimePicker className="dtPicker"
            onChange={this.onDateFromChange}
            disableCalendar={isMobile}
            value={this.props.dateFrom}
            maxDate={this.props.dateTo}
            minDate={new Date(-5000, 12, 31, 10)}
            maxDetail="hour"
            format="yyyy/MM/dd HH"
            calendarIcon={null}
            disableClock={true}
            clearIcon={null}
          /><span className="hour">h</span>

          <div className="arrow">
            <img src={'/img/middle-arrows.svg'} className="bracketimg" alt="<->" />
          </div>

          <DateTimePicker className="dtPicker"
            onChange={this.onDateToChange}
            disableCalendar={isMobile}
            value={this.props.dateTo}
            minDate={this.props.dateFrom}
            maxDate={new Date(4999, 12, 31, 11, 59)}
            maxDetail="hour"
            format="yyyy/MM/dd HH"
            calendarIcon={null}
            disableClock={true}
            clearIcon={null}
          /><span className="hour">h</span>

          <div className="bracket">
            <img src={'/img/bracket-post-right.svg'} className="bracketimg" alt="]" />
          </div>

        </div>

        <Button type="close" position="top-right-1" callbackAction={this.onClose} tip={'Close'} alt="X" />

      </div>

    );
  }
}

export default DateTimeDialog;
