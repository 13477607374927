import React from 'react';

import TimeTick from './TimeTick/TimeTick';

import './TimeScale.scss';

class TimeScale extends React.Component
{
  render()
  {
    return (
      <div className={'TimeScale '+this.props.className} >

        {
          this.props.timeTicks.map
          ((timeTickInfo)=>
            {
              return <TimeTick key={timeTickInfo.position} classType={this.props.className} timeInfo={timeTickInfo} />;
            }
          )
        }

      </div>
    );
  }
}

export default TimeScale;