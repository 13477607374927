import React from 'react';

import xodeService from '../../serviceclient/ServiceClient';
import dateEpoch from '../../serviceclient/dateEpoch';

import { isMobile } from "react-device-detect";

import Map from '../Map/Map';
import Timeglider from '../Timeglider/Timeglider';
import TimegliderMobile from '../TimegliderMobile/TimegliderMobile';
import TopButtons from '../TopButtons/TopButtons';
import ModalDialogBackground from '../ModalDialogBackground/ModalDialogBackground';
import SignInDialog from '../SignInDialog/SignInDialog';
import SignUpDialog from '../SignUpDialog/SignUpDialog';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import InfoDialog from '../InfoDialog/InfoDialog';
import ConnectingLoading from '../ConnectingLoading/ConnectingLoading';
import HowToPopUp from '../HowToPopUp/HowToPopUp';
import AccessDialog from '../AccessDialog/AccessDialog';
import Attributions from '../Attributions/Attributions';

import './App.scss';

class App extends React.Component
{
  constructor(props)
  {
    super(props);

    //capture landing post, if any
    this.landingPostId = this.getLandingPost(window.location.href);

    //initial TimeFrame: from 75 years back to 25 years ahead
    let dayFrom = -(75*365);
    let dayTo = 25*365;

    let initFrom = dateEpoch.currentDate(dayFrom);
    let initTo = dateEpoch.currentDate(dayTo);

    this.state =
    {
      appReady: false,

      userId: 0,
      userName: null,
      signedMode: false,
      userComunity: [],

      featurePosts:
      {
        type: 'FeatureCollection',
        features: []
      },

      timeFrame:
      {
        timeFrom: initFrom,
        timeTo: initTo
      },

      selectedPost: null,
      selectedPostReplies: null,

      currentNotifications: [],

      confirmDialog: null,
      infoDialog: null,

      showHowToPopUp: false,

      accessDialog: false //accessDialog: true
    }

    this.mapReady = false;
    this.MapReady = this.MapReady.bind(this);

    this.serviceReady = false;
    this.ServiceReady = this.ServiceReady.bind(this);
    this.SignedIn = this.SignedIn.bind(this);
    this.SignedUp = this.SignedUp.bind(this);

    this.OnSignInDialog = this.OnSignInDialog.bind(this);
    this.OnSignUpDialog = this.OnSignUpDialog.bind(this);
    this.OnDoSignIn = this.OnDoSignIn.bind(this);
    this.OnDoSignUp = this.OnDoSignUp.bind(this);
    this.OnSigningClose = this.OnSigningClose.bind(this);
    this.OnDoSignOut = this.OnDoSignOut.bind(this);

    this.OnTimeFrameChange = this.OnTimeFrameChange.bind(this);
    this.requestTimeFrameDialog = this.requestTimeFrameDialog.bind(this);
    this.OnTimeFrameDlgRequest = this.OnTimeFrameDlgRequest.bind(this);

    this.OnSelectedPost = this.OnSelectedPost.bind(this);

    this.goToPostId = this.goToPostId.bind(this);
    this.SetGoTo = this.SetGoTo.bind(this);
    this.OnGoHome = this.OnGoHome.bind(this);
    this.OnGoPost = this.OnGoPost.bind(this);

    this.OnConfirmDialog = this.OnConfirmDialog.bind(this);
    this.OnConfirmDone = this.OnConfirmDone.bind(this);

    this.OnInfoDialog = this.OnInfoDialog.bind(this);
    this.OnInfoDone = this.OnInfoDone.bind(this);

    this.OnPosts = this.OnPosts.bind(this);
    this.OnAddPostConfirm = this.OnAddPostConfirm.bind(this);
    this.OnAddPostNotice = this.OnAddPostNotice.bind(this);
    this.OnDeletePostConfirm = this.OnDeletePostConfirm.bind(this);
    this.OnDeletePostNotice = this.OnDeletePostNotice.bind(this);
    this.OnPostReplies = this.OnPostReplies.bind(this);
    this.OnReplyConfirm = this.OnReplyConfirm.bind(this);
    this.OnReplyNotice = this.OnReplyNotice.bind(this);
    this.OnPendingNotices = this.OnPendingNotices.bind(this);
    this.OnPrivatePostConfirm = this.OnPrivatePostConfirm.bind(this);
    this.OnPrivatePostNotice = this.OnPrivatePostNotice.bind(this);
    this.OnPublicPostConfirm = this.OnPublicPostConfirm.bind(this);
    this.OnPublicPostNotice = this.OnPublicPostNotice.bind(this);
    this.OnUserComunity = this.OnUserComunity.bind(this);

    this.OnConnectionLost = this.OnConnectionLost.bind(this);

    this.OnAccessCode = this.OnAccessCode.bind(this);
    this.OnShowHowTo = this.OnShowHowTo.bind(this);
    this.OnHowToDone = this.OnHowToDone.bind(this);
    this.isAnyDialogOpen = this.isAnyDialogOpen.bind(this);

    // Reference to timeglider.
    this.Timeglider = React.createRef();

    // Global variable
    window.$isMobile = isMobile;

  }

  // get landing post id, if any
  getLandingPost(url)
  {
    let tag = url.indexOf('#');
    if (tag > 0)
    {
      let postId = url.substring(tag+1);
      if (!isNaN(postId))
      {
        return parseInt(postId);
      }
      else
      {
        return 0;
      }
    }
    else
    {
      return 0;
    }
  }

  isAnyDialogOpen()
  {
    return (
      this.state.confirmDialog ||
      this.state.infoDialog ||
      this.state.signInDialog ||
      this.state.signUpDialog ||
      (this.state.showHowToPopUp && isMobile)
    );
  }

  OnAccessCode()
  {
    this.setState(
    {
      accessDialog: false
    });
  }

  OnShowHowTo()
  {
    this.setState(
    {
      showHowToPopUp: true
    });
  }
  OnHowToDone()
  {
    this.setState(
    {
      showHowToPopUp: false
    });
  }

  componentWillMount()
  {
    //setup service callbacks
    xodeService.setOnReady(this.ServiceReady);
    xodeService.setOnSignIn(this.SignedIn);
    xodeService.setOnSignUp(this.SignedUp);
    xodeService.setOnPosts(this.OnPosts);
    xodeService.setOnAddPostConfirm(this.OnAddPostConfirm);
    xodeService.setOnAddPostNotice(this.OnAddPostNotice);
    xodeService.setOnDeletePostConfirm(this.OnDeletePostConfirm);
    xodeService.setOnDeletePostNotice(this.OnDeletePostNotice);
    xodeService.setOnPostReplies(this.OnPostReplies);
    xodeService.setOnReplyConfirm(this.OnReplyConfirm);
    xodeService.setOnReplyNotice(this.OnReplyNotice);
    xodeService.setOnPendingNotices(this.OnPendingNotices);
    xodeService.setOnPrivatePostConfirm(this.OnPrivatePostConfirm);
    xodeService.setOnPrivatePostNotice(this.OnPrivatePostNotice);
    xodeService.setOnPublicPostConfirm(this.OnPublicPostConfirm);
    xodeService.setOnPublicPostNotice(this.OnPublicPostNotice);
    xodeService.setOnUserComunity(this.OnUserComunity);

    xodeService.setOnConnectionLost(this.OnConnectionLost);

    //setup service connection
    xodeService.connectService();

    window.addEventListener('resize', () => this.updateOnResize());
  }

  componentWillUnmountMount()
  {
    //logout close cleanup...
    xodeService.disconnectService();
  }

  UpdateReady()
  {
    this.setState(
    {
      appReady: this.serviceReady && this.mapReady
    });
  }

  MapReady(ready)
  {
    this.mapReady = ready;
    this.UpdateReady();
  }

  updateOnResize() {
    window.$isMobile = isMobile;
  }

  ServiceReady(ready, userFirstTimeEngagement=false)
  {
    if (ready)
    {
      this.setState(
      {
        userId: xodeService.currentUser.userId,
        userName: xodeService.currentUser.userName,
        signedMode: xodeService.currentUser.signedMode,

        showHowToPopUp: (userFirstTimeEngagement && (this.landingPostId === 0))
      });

      // request posts
      xodeService.getPosts();

      //clear and request pending notifications
      this.setState(
      {
        currentNotifications: []
      });

      // request pending notices
      xodeService.getPendingNotices();
    }

    this.serviceReady = ready;
    this.UpdateReady();
  }

  SignedIn(success)
  {
    if (!success)
    {
      //keep previous(still current) session
      this.serviceReady = true;
      this.UpdateReady();

      this.OnInfoDialog('sign in failed. check username and password.');
    }
  }

  SignedUp(success)
  {
    if (!success)
    {
      //keep previous(still current) session
      this.serviceReady = true;
      this.UpdateReady();

      this.OnInfoDialog('sign up failed. check provided email, it may be already in use.');
    }
  }

  OnTimeFrameChange(timeFrom, timeTo)
  {
    this.setState(
    {
      timeFrame:
      {
        timeFrom: timeFrom,
        timeTo: timeTo
      }
    });
  }

  requestTimeFrameDialog(requestTimeFrameDlg)
  {
    this.requestTimeFrameDlg = requestTimeFrameDlg
  }

  OnTimeFrameDlgRequest(showTimeFrameDlg)
  {
    if (this.requestTimeFrameDlg)
    {
      this.requestTimeFrameDlg();
    }
  }

  OnSelectedPost(post)
  {
    this.setState(
    {
      selectedPostReplies: null,
      selectedPost: post
    });

    if (post)
    {
      xodeService.getPostReplies(post.properties.postId);
    }
  }

  // go to post callback, from landing url or messages, to id on link
  goToPostId(postId)
  {
    let landingPost = this.state.featurePosts.features.find
    (
      post => post.properties.postId === postId
    );

    if (landingPost)
    {
      // to post timeframe
      this.goToTime(landingPost);

      // map fly to replied post
      this.goToPost(landingPost);
    }
  }

  SetGoTo(goHome, goPost)
  {
    this.goToHome = goHome;
    this.goToPost = goPost;
  }

  OnGoHome()
  {
    // to now
    this.goToTime(null);

    // map fly to user location or default location
    this.goToHome();
  }

  OnGoPost(post)
  {
    // update unseen replies
    this.setState(
    {
      currentNotifications: this.state.currentNotifications.filter(p => p.properties.postId !== post.properties.postId)
    });

    // to post timeframe
    this.goToTime(post);

    // map fly to replied post
    this.goToPost(post);
  }

  goToTime(post)
  {
    let timeFrom = dateEpoch.currentDate();
    let timeTo = dateEpoch.currentDate();

    if (post)
    {
      // timeframe to post timeframe
      timeFrom = dateEpoch.epochToDate(post.properties.epochFrom);
      timeTo = dateEpoch.epochToDate(post.properties.epochTo);
    }
    else
    {

      if (isMobile)
      {
        timeFrom.setHours(timeFrom.getHours() - 1);
        timeTo.setHours(timeTo.getHours() + 1);
      }
      else
      {
        timeFrom.setHours(timeFrom.getHours() - 1.5);
        timeTo.setHours(timeTo.getHours() + 1.5);
      }
    }

    // set timeframe
    this.OnTimeFrameChange(timeFrom, timeTo);
    // Force Timeglider to update.
    setTimeout(() => {
      this.Timeglider.current.updatedTimeFrame();
    }, 100);
  }

  OnPosts(featurePosts)
  {
    this.setState(
    {
      featurePosts: featurePosts
    },() =>
      {
        if (this.landingPostId > 0)
        {
          // go to requested landing post
          this.goToPostId(this.landingPostId);

          // disable landing post
          this.landingPostId = 0;
        }
      }
    );

    // request user comunity (connections)
    xodeService.getUserComunity();
  }

  OnAddPostConfirm(newPostId)
  {
    //TODO
    //handle just added post
    //this.state.featurePosts.features.push...
    //this.setState(
    //{
    //  featurePosts: featurePosts
    //});

    // provisionally:

    xodeService.getPosts();
  }

  OnAddPostNotice(newPostId)
  {
    //TODO
    //handle just added post
    //this.state.featurePosts.features.push...
    //this.setState(
    //{
    //  featurePosts: featurePosts
    //});

    // provisionally:

    xodeService.getPosts();
  }

  OnDeletePostConfirm(postId, success)
  {
    //TODO
    //handle just deleted post
    //this.state.featurePosts.features.slice...
    //this.setState(
    //{
    //  featurePosts: featurePosts
    //});

    // provisionally:

    if (success)
    {
      xodeService.getPosts();
    }
  }

  OnDeletePostNotice(postId)
  {
    //TODO
    //handle just deleted post
    //this.state.featurePosts.features.slice...
    //this.setState(
    //{
    //  featurePosts: featurePosts
    //});

    // provisionally:

    //if deleted post selected, unselect
    if (this.state.selectedPost && this.state.selectedPost.properties.postId === postId)
    {
      this.goToPost(null);
    }

    xodeService.getPosts();
  }

  OnReplyConfirm(postId, messageId)
  {
    if (this.state.selectedPost && this.state.selectedPost.properties.postId === postId)
    {
      xodeService.getPostReplies(postId);
    }

    // request to update user comunity
    xodeService.getUserComunity();
  }

  OnReplyNotice(postId, messageUserId, messageId)
  {
    // get post to check author
    let repliedPost = this.state.featurePosts.features.find
    (
        post => post.properties.postId === postId
    );

    //update notifications list and indicator button
    if ((repliedPost !== null) && (repliedPost.properties.userId === xodeService.currentUser.userId))
    {
      if (!this.state.currentNotifications.some(post => post.properties.postId === postId))
      {
        this.setState(
        {
          currentNotifications: [...this.state.currentNotifications, repliedPost]
        });
      }
    }

    //request all current replies on post if is currently selected
    if (this.state.selectedPost && this.state.selectedPost.properties.postId === postId)
    {
      xodeService.getPostReplies(postId);
    }

    // request to update user comunity
    xodeService.getUserComunity();
  }

  OnPostReplies(postId, postReplies)
  {
    if (this.state.selectedPost && this.state.selectedPost.properties.postId === postId)
    {
      this.setState(
      {
        selectedPostReplies: postReplies
      });
    }
  }

  OnPendingNotices(userId, pendingNotices)
  {
    // verify notices correspond
    if (userId === xodeService.currentUser.userId)
    {
      let noticesToAdd = [];

      //avoid repeat curreent notice
      pendingNotices.forEach(notice =>
      {
        if (!this.state.currentNotifications.some(post => post.properties.postId === notice))
        {
          // get replied post
          let repliedPost = this.state.featurePosts.features.find
          (
            post => post.properties.postId === notice
          );

          noticesToAdd.push(repliedPost);
        }
      });

      //update notifications list and indicator button
      this.setState(
      {
        currentNotifications: noticesToAdd.concat(this.state.currentNotifications)
      });
    }
  }

  OnPrivatePostConfirm(postId, success)
  {
    //TODO
    //handle just specific to-private post

    // provisionally:

    if (success)
    {
      xodeService.getPosts();
    }
  }

  OnPrivatePostNotice(postId)
  {
    //TODO
    //handle just specific to-private post

    // provisionally:

    //if to-private post selected, unselect
    if (this.state.selectedPost && this.state.selectedPost.properties.postId === postId)
    {
      this.goToPost(null);
    }

    xodeService.getPosts();
  }

  OnPublicPostConfirm(postId, success)
  {
    //TODO
    //handle just specific to-public post

    // provisionally:

    if (success)
    {
      xodeService.getPosts();
    }
  }

  OnPublicPostNotice(postId)
  {
    //TODO
    //handle just specific to-public post

    // provisionally:

    //if to-public post selected, unselect
    if (this.state.selectedPost && this.state.selectedPost.properties.postId === postId)
    {
      this.goToPost(null);
    }

    xodeService.getPosts();
  }

  OnUserComunity(userId, userComunity)
  {
    // verify if comunity data correspond
    if (userId === xodeService.currentUser.userId)
    {
      // sort for changes verfication
      userComunity.sort((Id1, Id2)=>{return Id1-Id2});

      // update, if changed
      if(userComunity.join(',') !== this.state.userComunity.join(','))
      {
        // update current user comunity connections
        this.setState(
        {
          userComunity: userComunity
        });
      }
    }
  }

  OnConnectionLost()
  {
    this.OnInfoDialog('Disconnected from service. Attempting to set new session.');
  }

  OnSignInDialog(callbackCancel)
  {
    this.setState(
    {
      signInDialog:
      {
        onCancel: callbackCancel
      }
    });
  }

  OnDoSignIn(username, password)
  {
    this.setState(
    {
      signInDialog: null
    });

    xodeService.signIn(username, password);
  }

  OnSignUpDialog(callbackCancel)
  {
    this.setState(
    {
      signUpDialog:
      {
        onCancel: callbackCancel
      }
    });
  }

  OnDoSignUp(email, password)
  {
    this.setState(
    {
      signUpDialog: null
    });

    xodeService.signUp(email, password);
  }

  OnSigningClose()
  {
    this.setState(
    {
      signInDialog: null,
      signUpDialog: null
    });
  }

  OnDoSignOut()
  {
    xodeService.signOut();
  }

  OnConfirmDialog(confirmMessage, callbackConfirm, callbackClose)
  {
    this.setState(
    {
      confirmDialog:
      {
        confirmMessage: confirmMessage,
        onConfirm: callbackConfirm,
        onClose: callbackClose
      }
    });
  }

  OnConfirmDone()
  {
    this.setState(
    {
      confirmDialog: null
    });
  }

  OnInfoDialog(infoMessage)
  {
    this.setState(
    {
      infoDialog:
      {
        infoMessage: infoMessage
      }
    });
  }

  OnInfoDone()
  {
    this.setState(
    {
      infoDialog: null
    });
  }

  render()
  {
    return (

      <div className={`App${window.$isMobile ? " isMobile" : ""}`}>

        <Map center={{lng:10, lat:20, zoom:1.4}} onReady={this.MapReady} featurePosts={this.state.featurePosts} timeFrame={this.state.timeFrame} onTimeFrameDlgRequest={this.OnTimeFrameDlgRequest} callbackSelectedPost={this.OnSelectedPost} currentPostReplies={this.state.selectedPostReplies} userId={xodeService.currentUser.userId} userComunity={this.state.userComunity} setGoTo={this.SetGoTo} goToPostCallback={this.goToPostId} confirmDialog={this.OnConfirmDialog} isAnyDialogOpen={this.isAnyDialogOpen}/>

        {
          !isMobile &&
          <Timeglider ref={this.Timeglider} timeFrame={this.state.timeFrame} callbackTimeFrameChange={this.OnTimeFrameChange} setRequestTimeFrameDialog={this.requestTimeFrameDialog} isAnyDialogOpen={this.isAnyDialogOpen} />
        }
        {
          isMobile &&
          <TimegliderMobile ref={this.Timeglider} timeFrame={this.state.timeFrame} callbackTimeFrameChange={this.OnTimeFrameChange} setRequestTimeFrameDialog={this.requestTimeFrameDialog} isAnyDialogOpen={this.isAnyDialogOpen} />
        }

        <TopButtons currentNotifications={this.state.currentNotifications} onGoPost={this.OnGoPost} onGoHome={this.OnGoHome} userId={this.state.userId} userName={this.state.userName} signedMode={this.state.signedMode} signInDialog={this.OnSignInDialog} signUpDialog={this.OnSignUpDialog} onSignOut={this.OnDoSignOut} confirmDialog={this.OnConfirmDialog} showHowToCallback={this.OnShowHowTo} />

        {this.state.showHowToPopUp && <HowToPopUp callbackDone={this.OnHowToDone} />}

        {(!this.state.appReady || this.state.accessDialog) && <ConnectingLoading className="App" />}

        {this.isAnyDialogOpen() && <ModalDialogBackground />}

            {this.state.signInDialog && <SignInDialog onDoSignIn={this.OnDoSignIn} callbackCancel={this.state.signInDialog.onCancel} callbackDone={this.OnSigningClose} confirmDialog={this.OnConfirmDialog} />}
            {this.state.signUpDialog && <SignUpDialog userName={xodeService.currentUser.userName} onDoSignUp={this.OnDoSignUp} callbackCancel={this.state.signUpDialog.onCancel} callbackDone={this.OnSigningClose} confirmDialog={this.OnConfirmDialog} />}

            {this.state.confirmDialog && <ConfirmDialog confirmMessage={this.state.confirmDialog.confirmMessage} callbackConfirm={this.state.confirmDialog.onConfirm} callbackClose={this.state.confirmDialog.onClose} callbackDone={this.OnConfirmDone} />}
            {this.state.infoDialog && <InfoDialog infoMessage={this.state.infoDialog.infoMessage} callbackDone={this.OnInfoDone} />}

        {this.state.accessDialog && <AccessDialog callbackSubmit={this.OnAccessCode} />}

        <Attributions />

      </div>
    );
  }
}

export default App;
