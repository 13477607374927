import React from 'react';

import TimeLine from './TimeLine/TimeLine';
import TimeFrame from './TimeFrame/TimeFrame';

import './Timeglider.scss';
import dateEpoch from '../../serviceclient/dateEpoch';
import DateTimeDialog from './DateTimeDialog/DateTimeDialog';
import { isIOS } from 'react-device-detect';

class Timeglider extends React.Component
{
  constructor(props)
  {
    super(props);

    let initTimeFrom = this.props.timeFrame.timeFrom;
    let initTimeTo = this.props.timeFrame.timeTo;

    let initTimeFrameEpochFrom = dateEpoch.dateToEpoch(initTimeFrom);
    let initTimeFrameEpochTo = dateEpoch.dateToEpoch(initTimeTo);
    let initTimeFrameSpan = initTimeFrameEpochTo - initTimeFrameEpochFrom;

    let initTimeRangeFrom = initTimeFrameEpochFrom - initTimeFrameSpan;
    let initTimeRangeTo = initTimeFrameEpochTo + initTimeFrameSpan;

    this.state =
    {
      timeRange:
      {
        timeFrom: dateEpoch.epochToDate(initTimeRangeFrom),
        timeTo: dateEpoch.epochToDate(initTimeRangeTo)
      },

      showDateTimeDialog: false
    }

    this.updatedTimeFrame = this.updatedTimeFrame.bind(this);
    this.TimeFrameChanged = this.props.callbackTimeFrameChange;
    this.isAnyDialogOpen = this.props.isAnyDialogOpen;

    this.OnTimeGliding = this.OnTimeGliding.bind(this);
    this.setTimeFrameChanged = this.setTimeFrameChanged.bind(this);
    this.OnTimeFraming = this.OnTimeFraming.bind(this);
    this.OnTimeFrameReset = this.OnTimeFrameReset.bind(this);
    this.timeLabelClicked = this.timeLabelClicked.bind(this);

    this.props.setRequestTimeFrameDialog(this.timeLabelClicked);

    // Reference to TimeFrame.
    this.TimeFrame = React.createRef();
  }

  OnTimeGliding(glideto, glideInfo)
  {
    let rangeFrom = dateEpoch.dateToEpoch(this.state.timeRange.timeFrom);
    let rangeTo = dateEpoch.dateToEpoch(this.state.timeRange.timeTo);

    switch (glideto)
    {
      case 'future':
        {
          rangeFrom = rangeFrom + glideInfo.offset;
          rangeTo = rangeTo + glideInfo.offset;
        }
        break;
      case 'past':
        {
          rangeFrom = rangeFrom - glideInfo.offset;
          rangeTo = rangeTo - glideInfo.offset;
        }
        break;
      case 'zoomin':
        {
          let offset = glideInfo.offset * 2;
          let proportion_from =  offset * (glideInfo.mouse / 100);
          let proportion_to = (offset - proportion_from);
          rangeFrom = rangeFrom + proportion_from;
          rangeTo = rangeTo - proportion_to;
        }
        break;
      case 'zoomout':
        {
          let offset = glideInfo.offset * 2;
          let proportion_from =  offset * (glideInfo.mouse / 100);
          let proportion_to = (offset - proportion_from);
          rangeFrom = rangeFrom - proportion_from;
          rangeTo = rangeTo + proportion_to;
        }
        break;
      case 'center':
        {
          let epochSpan = Math.abs(rangeTo - rangeFrom);
          let rangeCenter = rangeFrom + Math.floor(epochSpan * glideInfo.centerAt);
          rangeFrom = rangeCenter - epochSpan/2;
          rangeTo = rangeCenter + epochSpan/2;
        }
        break;
      default:
        {
          //keep timerange
        }
        break;
    }

    //check TimeRange limits
    let epochMin = dateEpoch.epochMin();
    let epochMax = dateEpoch.epochMax();
    if (rangeFrom < epochMin)
    {
      rangeFrom = epochMin;
    }
    if (rangeTo > epochMax)
    {
      rangeTo = epochMax;
    }
    let timerangeSpan = Math.abs(rangeTo - rangeFrom);
    if ((rangeFrom < rangeTo)
        && (timerangeSpan >= dateEpoch.timeRangeMin())
        && (timerangeSpan <= dateEpoch.timeRangeMax()))
    {
      let timerangeFrom = dateEpoch.epochToDate(rangeFrom);
      let timerangeTo = dateEpoch.epochToDate(rangeTo);

      this.setState(
      {
        timeRange:
        {
          timeFrom: timerangeFrom,
          timeTo: timerangeTo
        }
      });

    }
    // verify if TimeFrame changed due to timegliding
    if (this.checkTimeFrameChanged)
    {
      this.checkTimeFrameChanged();
    }
  }

  setTimeFrameChanged(checkTimeFrameChanged)
  {
    this.checkTimeFrameChanged = checkTimeFrameChanged;
  }

  OnTimeFraming(timeFrom, timeTo)
  {
    this.TimeFrameChanged(timeFrom, timeTo);
  }

  OnTimeFrameReset(timeFrom, timeTo)
  {
    let initTimeFrom = timeFrom;
    let initTimeTo = timeTo;

    let initTimeFrameEpochFrom = dateEpoch.dateToEpoch(initTimeFrom);
    let initTimeFrameEpochTo = dateEpoch.dateToEpoch(initTimeTo);
    let initTimeFrameSpan = initTimeFrameEpochTo - initTimeFrameEpochFrom;

    let initTimeRangeFrom = initTimeFrameEpochFrom - initTimeFrameSpan;
    let initTimeRangeTo = initTimeFrameEpochTo + initTimeFrameSpan;

    this.setState(
    {
      timeRange:
      {
        timeFrom: dateEpoch.epochToDate(initTimeRangeFrom),
        timeTo: dateEpoch.epochToDate(initTimeRangeTo)
      }
    });

    this.TimeFrameChanged(timeFrom, timeTo);
  }

  timeLabelClicked(_e)
  {
    this.setState(
    {
      showDateTimeDialog: !this.state.showDateTimeDialog
    });
  }

  // Something else updated the timeframe (ex: clicking on "bring me home now").
  updatedTimeFrame() {
    let initTimeFrom = this.props.timeFrame.timeFrom;
    let initTimeTo = this.props.timeFrame.timeTo;

    let initTimeFrameEpochFrom = dateEpoch.dateToEpoch(initTimeFrom);
    let initTimeFrameEpochTo = dateEpoch.dateToEpoch(initTimeTo);
    let initTimeFrameSpan = initTimeFrameEpochTo - initTimeFrameEpochFrom;

    let initTimeRangeFrom = initTimeFrameEpochFrom - initTimeFrameSpan;
    let initTimeRangeTo = initTimeFrameEpochTo + initTimeFrameSpan;

    this.setState(
    {
      timeRange:
      {
        timeFrom: dateEpoch.epochToDate(initTimeRangeFrom),
        timeTo: dateEpoch.epochToDate(initTimeRangeTo)
      }
    });
    this.TimeFrame.current.fixBracketPosition();
  }

  render()
  {
    let specialOnClick = null;
    if (isIOS)
    {
      specialOnClick = this.timeLabelClicked;
    }
    return (

      <div id="TimeGlider" onClick={specialOnClick} className="Timeglider" style={{ display: this.isAnyDialogOpen() ? 'none' : 'inherit' }} >
        <TimeLine timeRange={this.state.timeRange} callbackTimeGliding={this.OnTimeGliding} />

        <TimeFrame ref={this.TimeFrame} timeFrame={this.props.timeFrame} timeRange={this.state.timeRange} setTimeFrameChanged={this.setTimeFrameChanged} callbackTimeFraming={this.OnTimeFraming} timeLabelClicked={this.timeLabelClicked} />

        {this.state.showDateTimeDialog && <DateTimeDialog dateFrom={this.props.timeFrame.timeFrom} dateTo={this.props.timeFrame.timeTo} callbackDatesChanged={this.OnTimeFrameReset} callbackCloseDialog={this.timeLabelClicked} />}

      </div>

    );
  }
}

export default Timeglider;
