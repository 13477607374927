import React from 'react';

import xodeService from '../../../../serviceclient/ServiceClient';
import dateEpoch from '../../../../serviceclient/dateEpoch';
import Button from '../../../Button/Button';

import './NewPostComponent.scss';

class NewPostComponent extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      title: 'Title...',
      titleValid: false,

      content: 'Message...',
      contentValid: false,

      changedTitle: false,
      changedContent: false,

      timeframeStatus: 'blink'
    };

    this.OnEnterTimeFrame = this.OnEnterTimeFrame.bind(this);
    this.OnClickTimeFrame = this.OnClickTimeFrame.bind(this);
    this.OnTitleChanged = this.OnTitleChanged.bind(this);
    this.OnContentChanged = this.OnContentChanged.bind(this);
    this.OnTitleFocus = this.OnTitleFocus.bind(this);
    this.OnContentFocus = this.OnContentFocus.bind(this);

    this.OnCancel = this.OnCancel.bind(this);
    this.OnSubmit = this.OnSubmit.bind(this);

    this.OnCancelConfirm = this.OnCancelConfirm.bind(this);
    this.OnCancelClose = this.OnCancelClose.bind(this);
  }

  doneNewPost()
  {
    // dissmiss new post popup
    this.props.callbackClose();

    // reset inital status
    this.setState(
    {
      title: 'Title...',
      titleValid: false,

      content: 'Message...',
      contentValid: false,

      changedTitle: false,
      changedContent: false,

      timeframeStatus: 'blink'
    });

    document.body.classList.remove('modal-open');
    document.body.classList.remove('no-timeline');
  }  
  
  OnEnterTimeFrame()
  {
    this.setState(
    {
      timeframeStatus: 'quiet'
    });
  }

  OnClickTimeFrame()
  {
    this.props.onTimeFrameDlgRequest();
  }

  OnTitleChanged(e)
  {
    let newTitle = (e.target.value === null || e.target.value === undefined || e.target.value === ' ') ? '' : e.target.value;

    this.setState(
    {
      title: newTitle,
      titleValid: (newTitle !== ''),

      changedTitle: true
    });
  }

  OnTitleFocus(e)
  {
    if (!this.state.changedTitle || !this.state.titleValid)
    {
      let newTitle = '';
      e.target.value = newTitle;

      this.setState(
      {
        title: newTitle,
        titleValid: false,
        changedTitle: true
      });
    }
  }

  OnContentChanged(e)
  {
    let newContent = (e.target.value === null || e.target.value === undefined || e.target.value === ' ') ? '' : e.target.value;

    this.setState(
    {
      content: newContent,
      contentValid: (newContent !== ''),

      changedContent: true
    });
  }

  OnContentFocus(e)
  {
    if (!this.state.changedContent || !this.state.contentValid)
    {
      let newContent = '';
      e.target.value = newContent;
      this.setState(
      {
        content: newContent,
        contentValid: false,
        changedContent: true
      });
    }
  }

  OnSubmit()
  {
    if (this.state.titleValid && this.state.contentValid)
    {
      // set new Post
      let newPostFeature = xodeService.setPost(dateEpoch.currentDateTime(), this.props.location.lng, this.props.location.lat, this.state.title, this.state.content, this.props.timeFrame.timeFrom, this.props.timeFrame.timeTo, this.props.map.getZoom());

      // add post request
      xodeService.addPost(newPostFeature);

      this.doneNewPost();
    }
    else
    {

      if (!this.state.titleValid)
      {
        this.setState(
        {
          changedTitle: true,
          title: 'Title...'
        });
      }

      if (!this.state.contentValid)
      {
        this.setState(
        {
          changedContent: true,
          content: 'Message...'
        });
      }

    }
  }

  OnCancel()
  {
    if ((this.state.changedTitle && this.state.titleValid) || (this.state.changedContent && this.state.contentValid))
    {
      this.props.confirmDialog("Do you really want to discard your new message?", this.OnCancelConfirm, this.OnCancelClose);
    }
    else
    {
      this.doneNewPost();
    }
  }

  OnCancelConfirm()
  {
    this.doneNewPost();
  }

  OnCancelClose()
  {
    // keep on state
  }

  render()
  {
    let timeFrameText = '[ ' + dateEpoch.orderedStringDate(this.props.timeFrame.timeFrom) + ' - ' + dateEpoch.orderedStringDate(this.props.timeFrame.timeTo) + ' ]';

    const buttonsSide = (this.props.anchor === 'right') ? 'left' : 'right' ;

    return (

      <div className="NewPostComponent SelectedPostComponent">

        <div className={'TimeFrameNew '+this.state.timeframeStatus}
          onMouseEnter={this.OnEnterTimeFrame}
          onClick={this.OnClickTimeFrame}>
          {timeFrameText}
        </div>

        <input
          className="TitleIn"
          type="text"

          value={this.state.title}
          maxLength="30"

          style={{color: this.state.titleValid ? 'black' : (this.state.changedTitle ? 'red' : 'gray')}}

          onChange={this.OnTitleChanged}
          onFocus={this.OnTitleFocus}
          onClick={this.OnTitleFocus}
        />

        <div className="NewPostSectionsSeparator" />

        <textarea
          className="ContentIn"
          rows="5"

          value={this.state.content}

          style={{color: this.state.contentValid ? 'black' : (this.state.changedContent ? 'red' : 'gray')}}

          onChange={this.OnContentChanged}
          onFocus={this.OnContentFocus}
          onClick={this.OnContentFocus}
        />

        <Button type="close" position={'top-'+buttonsSide+'-1 popup'} callbackAction={this.OnCancel} tip={'Cancel'} alt="X" />
        <Button type="submit" position={'right-bottom-2 popup Button--NewPost'} callbackAction={this.OnSubmit} tip={'Submit post'} alt=">" />

      </div>
    );
  }
}

export default NewPostComponent;
