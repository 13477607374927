import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

import './Button.scss';

class Button extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      mode: 'normal',
      showTip: false
    }

    this.callbackAction = this.props.callbackAction;
    this.invokeCallbackAction = this.invokeCallbackAction.bind(this);
    this.buttonEnter = this.buttonEnter.bind(this);
    this.buttonLeave = this.buttonLeave.bind(this);

    if (this.props.modeOverride)
    {
      this.modeOverride = this.modeOverride.bind(this);
      this.props.modeOverride(this.modeOverride);
    }
  }

  buttonEnter() {
    this.setState(
    {
      mode: !window.$isMobile ? 'rollover' : 'normal',
      showTip: true
    });
  }

  buttonLeave() {
    this.setState(
    {
      mode: this.props.rolloverOverride && !window.$isMobile ? 'rollover' : 'normal',
      showTip: false
    });
  }

  invokeCallbackAction(_e)
  {
    this.setState(
    {
      mode: this.props.rolloverOverride ? 'rollover' : 'normal',
      showTip: false
    },this.callbackAction());
  }

  modeOverride(mode) {
    this.setState(
    {
      mode: mode
    });
  }

  render()
  {
    return (

      <Tippy disabled={!this.props.tip} content={this.props.tip} visible={this.state.showTip} duration="0" disabled={window.$isMobile}>

        <div className={'Button '+this.props.position}
          onMouseEnter={this.buttonEnter}
          onMouseLeave={this.buttonLeave}
          onClick={this.invokeCallbackAction}
          onMouseDown={(e) => {e.preventDefault();}}
        >

          <img src={'/img/'+this.props.type+'_'+this.state.mode+'.svg'} className="buttonimg" alt={this.props.alt} />

        </div>

     </Tippy>

    );
  }
}

export default Button;