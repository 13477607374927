import React from 'react';

import { isMobile } from "react-device-detect";
import PopUpComponent from '../PopUpComponent/PopUpComponent';
import SelectedPostComponent from '../PopUpComponent/PopUp/SelectedPostComponent/SelectedPostComponent';
import NewPostComponent from '../PopUpComponent/PopUp/NewPostComponent/NewPostComponent';
import OverPostComponent from '../PopUpComponent/PopUp/OverPostComponent/OverPostComponent';
import PreMarker from '../PreMarker/PreMarker';

import './Map.css';

//Mapbox SDK
import mapboxgl from 'mapbox-gl';

//Mapbox account acces token
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

class Map extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            centerLocation:
                {
                    lng: this.props.center.lng,
                    lat: this.props.center.lat
                },

            newLocation: null,

            overLocation: null,
            overPost: null,

            postLocation: null,
            selectedPost: null,

            currentPosts: this.currentPosts,

            clickIsBottom: false
        };

        this.isAnyDialogOpen = this.props.isAnyDialogOpen;

        // feature Posts collection
        this.currentPosts = this.props.featurePosts;

        // current TimeFrame
        this.currentTimeFrame = this.props.timeFrame;

        //Map Container
        this.mapContainerRef = React.createRef();

        //Mapbox SDK map
        this.map = null;

        // PostsPopUps callbacks
        this.CloseNewPost = this.CloseNewPost.bind(this);
        this.CloseSelectedPost = this.CloseSelectedPost.bind(this);

        // post select report
        this.SelectedPost = this.props.callbackSelectedPost;

        // selected post track flag
        this.selectedPostId = 0;

        // GoTo functions
        this.goHome = this.goHome.bind(this);
        this.goPost = this.goPost.bind(this);
        this.props.setGoTo(this.goHome, this.goPost);

        // go to post callback, from messages, to id on link
        this.goToPostCallback = this.goToPostCallback.bind(this);
    }

    // go to post callback, from messages, to id on link
    goToPostCallback(posiId)
    {
      this.props.goToPostCallback(posiId);
    }

    // initialize when component mounts
    componentDidMount()
    {
        window.addEventListener('resize', () => this.updateOnResize());

        // set mapbox SDK instance
        this.map = new mapboxgl.Map(
        {
            container: this.mapContainerRef.current,
            style: process.env.REACT_APP_BASEMAP,
            center: [this.props.center.lng, this.props.center.lat],
            zoom: this.props.center.zoom,
            keyboard: false,
            trackResize: false
        });

        // disable map rotation using right click + drag
        this.map.dragRotate.disable();

        // disable map rotation using touch rotation gesture
        this.map.touchZoomRotate.disableRotation();

        // setup x-ode map
        this.map.on('load', () =>
        {
            this.map.loadImage('/img/pin.png',
            (error, pinMarker) =>
            {
                if (error) throw error;

                this.map.addImage('marker', pinMarker, { 'sdf': true });
                this.map.addSource('xode_source',
                {
                    'type': 'geojson',
                    'data': this.currentPosts,
                });

                // setup x-ode dots layer
                this.map.addLayer(
                {
                    'id': 'xode_dots',
                    'type': 'circle',
                    'source': 'xode_source',

                    'layout':
                    {
                        'circle-sort-key':
                        ['+',
                            ['match', ['get', 'comunityConnectionType'],
                                'self', 4, // own posts (most prioritized to show)
                                'connected', 3, // comunity users posts (second high priority)
                                'others', 2, // not connected, rest of users posts (normal priority)
                                1 // default (least priority)
                            ],
                            ['-', 1, ['/', 1, ['get', 'postId']]] //newest first
                        ]
                    },

                    'paint':
                    {
                        'circle-radius': 3,// match "X" marker middle dot

                        'circle-color':
                        ['match', ['get', 'comunityConnectionType'],
                            'self', '#FF0000', // own posts (red)
                            'connected', '#FF8C00', // comunity users posts (orange)
                            'others', '#000000', // not connected, rest of users posts (black)
                            '#000000' // default (black)
                        ],

                        'circle-blur':
                        ['let', 'postZoom', ['get', 'postZoom'],
                        ['interpolate', ['linear'], ['zoom'], //zoom back posts blured
                            0,['case', ['<', ['var', 'postZoom'], 0], 1, 0],
                            9,['case', ['<', ['var', 'postZoom'], 0], 1, 0],
                            10,['case', ['<', ['var', 'postZoom'], 3], 1, 0],
                            11,['case', ['<', ['var', 'postZoom'], 4], 1, 0],
                            12,['case', ['<', ['var', 'postZoom'], 5], 1, 0],
                            13,['case', ['<', ['var', 'postZoom'], 6], 1, 0],
                            14,['case', ['<', ['var', 'postZoom'], 7], 1, 0],
                            15,['case', ['<', ['var', 'postZoom'], 8], 1, 0],
                            16,['case', ['<', ['var', 'postZoom'], 9], 1, 0],
                            17,['case', ['<', ['var', 'postZoom'], 10], 1, 0],
                            18,['case', ['<', ['var', 'postZoom'], 11], 1, 0],
                            19,['case', ['<', ['var', 'postZoom'], 12], 1, 0],
                            20,['case', ['<', ['var', 'postZoom'], 13], 1, 0],
                            21,['case', ['<', ['var', 'postZoom'], 14], 1, 0],
                            22,['case', ['<', ['var', 'postZoom'], 15], 1, 0]
                        ]],
                        'circle-opacity':
                        ['let', 'postZoom', ['get', 'postZoom'],
                        ['interpolate', ['linear'], ['zoom'], //zoom back posts dimished to emphasize blur
                            0,['case', ['<', ['var', 'postZoom'], 0], 0.5, 1],
                            9,['case', ['<', ['var', 'postZoom'], 0], 0.5, 1],
                            10,['case', ['<', ['var', 'postZoom'], 3], 0.5, 1],
                            11,['case', ['<', ['var', 'postZoom'], 4], 0.5, 1],
                            12,['case', ['<', ['var', 'postZoom'], 5], 0.5, 1],
                            13,['case', ['<', ['var', 'postZoom'], 6], 0.5, 1],
                            14,['case', ['<', ['var', 'postZoom'], 7], 0.5, 1],
                            15,['case', ['<', ['var', 'postZoom'], 8], 0.5, 1],
                            16,['case', ['<', ['var', 'postZoom'], 9], 0.5, 1],
                            17,['case', ['<', ['var', 'postZoom'], 10], 0.5, 1],
                            18,['case', ['<', ['var', 'postZoom'], 11], 0.5, 1],
                            19,['case', ['<', ['var', 'postZoom'], 12], 0.5, 1],
                            20,['case', ['<', ['var', 'postZoom'], 13], 0.5, 1],
                            21,['case', ['<', ['var', 'postZoom'], 14], 0.5, 1],
                            22,['case', ['<', ['var', 'postZoom'], 15], 0.5, 1]
                        ]],
                    }
                });

                // setup x-ode layer
                this.map.addLayer(
                {
                    'id': 'xode_layer',
                    'type': 'symbol',
                    'source': 'xode_source',
                    'minzoom': 3, // no posts shown at global <3 zoom

                    'layout':
                    {
                        'icon-image': 'marker',
                        'icon-anchor': 'center',
                        'icon-size': 1.0, //currently for 25x25
                        'icon-allow-overlap': false,

                        'symbol-sort-key':
                        ['case',
                            ['to-boolean',['get', 'selected']], 0, // selected post (in use, required to show)
                            ['+',
                                ['match', ['get', 'comunityConnectionType'],
                                    'self', 1, // own posts (most prioritized to show)
                                    'connected', 2, // comunity users posts (second high priority)
                                    'others', 3, // not connected, rest of users posts (normal priority)
                                    4 // default (least priority)
                                ],
                                ['/', 1, ['get', 'postId']] //newest first
                            ]
                        ],
                    },

                    'paint':
                    {
                        'icon-color':
                        ['match', ['get', 'comunityConnectionType'],
                            'self', '#FF0000', // own posts (red)
                            'connected', '#FF8C00', // comunity users posts (orange)
                            'others', '#000000', // not connected, rest of users posts (black)
                            '#000000' // default (black)
                        ]
                    }
                });

                // hide x-ode layer until timeframe and context set
                this.map.setLayoutProperty('xode_layer', 'visibility', 'none');

                // disable onload reinitialization
                this.map.off('load');

                // proceed with basemap ready
                this.props.onReady(true);

                // set timeframe context
                this.setTimeFrameFilter();

                // show x-ode layer with timeframe context set
                this.map.setLayoutProperty('xode_layer', 'visibility', 'visible');
            });
        });

        // handle zoom change for zoom range filter
        this.map.on('zoom', () =>
        {
            //update zoom range (and timeframe) filter
            this.setTimeFrameFilter();
        });

        // set popup for new Post, on click
        this.mapClicks = 0;//map not clicked initial set
        this.map.on('click', e =>
        {
            // prevent new post when existent post is selected
            if (this.map.queryRenderedFeatures(e.point,{layers: ['xode_layer']}).length !== 0) { return; }

            this.mapClicks++;//map clicked count

            if (this.mapClicks < 2)//map single click check
            {
                //watch touches
                this.watchTouches = true;

                // get click if not doubleclick
                setTimeout(()=>
                {
                    // check if not doubleclicked
                    if (this.mapClicks < 2)//(!this.map.doubleClickZoom.isActive())
                    {
                        let clickZoom = this.map.getZoom();
                        // avoid post at global zoom and post overlap
                        if ((clickZoom >= 3) && (this.map.queryRenderedFeatures(e.point,{layers: ['xode_layer']}).length === 0))
                        {
                            // get coordinates
                            const location = e.lngLat;

                            // process click on map
                            this.mapClicked(location);
                        }
                    }

                    this.mapClicks = 0;//map not clicked reset
                    this.watchTouches = false;//stop watch touches
                }
                , 333);//third of second margin for doubleclick
            }
        });
        //check doubletap on mobile
        this.watchTouches = false;
        this.map.on('touchstart', () =>
        {
            //count another tap
            if (this.watchTouches)
            {
                this.mapClicks++;
            }
        });

        // set popup for Post, on mouse over
        this.map.on('mouseenter', 'xode_layer', e =>
        {
            this.map.getCanvas().style.cursor = 'pointer';

            // get Post info
            const currentFeature = e.features[0];
            const postId = currentFeature.properties.postId;

            // get Post
            const currentPost = this.currentPosts.features.find
            (
                post => post.properties.postId === postId
            );

            // get Coordinates
            const location =
            {
                lng: currentPost.geometry.coordinates[0],
                lat: currentPost.geometry.coordinates[1]
            };

            // process enter on post
            this.mapEnteredPost(location, currentPost);

        });

        // dismiss popup for Post, on mouse leave
        this.map.on('mouseleave', 'xode_layer', e =>
        {
            this.map.getCanvas().style.cursor = '';

            // process leave post
            this.mapLeavedPost();
        });

        // set popup for Post, on Post click
        this.map.on('click', 'xode_layer', e =>
        {
            // get Post info
            const currentFeature = e.features[0];
            const postId = currentFeature.properties.postId;

            // get Post
            const currentPost = this.currentPosts.features.find
            (
              post => post.properties.postId === postId
            );

            // get Coordinates
            const location =
            {
                lng: currentPost.geometry.coordinates[0],
                lat: currentPost.geometry.coordinates[1]
            };

            // Mobile behavior
            if (window.$isMobile)
            {
                this.setState(
                    {
                        newLocation: null
                    });
                this.disableControls();
                document.body.classList.add('modal-open');
                document.body.classList.add('no-timeline');
            }

            // process post
            this.mapClickedPost(location, currentPost);

        });

        this.map.on('moveend', async () =>
        {
            // get center coordinates
            const location = this.map.getCenter();

            // process map center moved
            this.mapMoved(location);

        });

        // Manually resize the map only if we are not creating a new post.
        // Android will resize the map when displaying the soft keyboard, the
        // resize triggers the map to resize too and it blurs the active
        // textfield, closing the keyboard again.
        window.addEventListener('resize', () => {
          if (!isMobile || (!this.state.newLocation && !this.state.selectedPost && !this.isAnyDialogOpen())) {
            this.map.resize();
          }
        });

    }

    updateOnResize()
    {
        if (!window.$isMobile) this.enableControls();
    }

    disableControls()
    {
        this.map.boxZoom.disable();
        this.map.scrollZoom.disable();
        this.map.dragPan.disable();
        this.map.dragRotate.disable();
        this.map.keyboard.disable();
        this.map.doubleClickZoom.disable();
        this.map.touchZoomRotate.disable();
    }

    enableControls()
    {
        this.map.boxZoom.enable();
        this.map.scrollZoom.enable();
        this.map.dragPan.enable();
        this.map.dragRotate.enable();
        this.map.keyboard.enable();
        this.map.doubleClickZoom.enable();
        this.map.touchZoomRotate.enable();
    }

    setTimeFrameFilter()
    {
        //now setting timeframe filter
        //and zoom range filter as well

        // convert Dates to epoch(xms)
        var epochFrom = Math.round(this.currentTimeFrame.timeFrom.getTime());//epoch(xms). /1000->epoch''
        var epochTo = Math.round(this.currentTimeFrame.timeTo.getTime());//epoch(xms). /1000->epoch''

        //get current zoom
        let currentZoom = this.map.getZoom();

        var filtersTimeZoom =
        [
            "all",

            // inclusive, timeframe interception
            ['>=', 'epochTo', epochFrom],
            ['<=', 'epochFrom', epochTo],

            // zoom range extent, [-7 <- z -> +7]
            ['>=', 'postZoom', currentZoom-7],
            ['<=', 'postZoom', currentZoom+7]
        ];

        try
        {
            this.map.setFilter('xode_layer', filtersTimeZoom);
        }
        catch
        {
            //
        }

        // filter dots layer
        var filterDots =
        [
            "all",

            // inclusive, timeframe interception
            ['>=', 'epochTo', epochFrom],
            ['<=', 'epochFrom', epochTo],
        ];
        try
        {
            this.map.setFilter('xode_dots', filterDots);
        }
        catch
        {
            //
        }
    }

    componentDidUpdate(prevProps)
    {
        // refresh map posts
        this.currentPosts = this.props.featurePosts;

        // refresh posts color comunity code
        this.currentPosts.features.forEach(post =>
        {
            if (post.properties.userId === this.props.userId)
            {
                post.properties.comunityConnectionType = 'self'
            }
            else if (this.props.userComunity.includes(post.properties.userId))
            {
                post.properties.comunityConnectionType = 'connected'
            }
            else
            {
                post.properties.comunityConnectionType = 'others'
            }
        });

        // attempt to update map posts/markers
        try
        {
            this.map.getSource('xode_source').setData(this.currentPosts);
        }
        catch
        {
            //
        }

        // refresh timeframe filter if required
        if (this.props.timeFrame !== prevProps.timeFrame)
        {
            this.currentTimeFrame = this.props.timeFrame;

            // set timeframe context
            this.setTimeFrameFilter();
        }
    }

    componentWillUnmount()
    {
        // release map instance
        return () => this.map.remove();
    }

    mapClicked(location)
    {
        if (window.$isMobile) {
            const center = this.map.getCenter();
            const currentZoom = this.map.getZoom();
            const centerY = this.map.project([center.lng, center.lat]).y;
            const locationY = this.map.project([location.lng, location.lat]).y;

            this.setState({
                clickIsBottom: locationY > (centerY - 70) && currentZoom < 1.4
            });

            this.CloseSelectedPost();
            this.disableControls();
            document.body.classList.add('modal-open');
            document.body.classList.add('no-timeline');
            let timeGlider = document.getElementById('TimeGlider');
            let heightOffset = 0;
            if (timeGlider) {
              heightOffset = timeGlider.offsetHeight / -2;
            }
            this.map.flyTo({
                center: location,
                offset: window.$isMobile ?
                  [-((window.innerWidth / 2) - 65), -((window.innerHeight / 4) + 130)] :
                  [0, heightOffset],
            });
        }

        this.setState(
        {
            newLocation: location
        });
    }

    mapEnteredPost(location, featurePost)
    {
        if (
                !this.state.postLocation ||
                location.lng !== this.state.postLocation.lng ||
                location.lat !== this.state.postLocation.lat
            )
        {
            this.setState(
            {
                overLocation: location,
                overPost: featurePost
            });
        }
    }

    mapLeavedPost()
    {
        this.setState(
        {
            overLocation: null,
            overPost: null
        });
    }

    mapClickedPost(location, featurePost)
    {
        if (
                this.state.overLocation &&
                location.lng === this.state.overLocation.lng &&
                location.lat === this.state.overLocation.lat
            )
        {
            this.setState(
            {
                overLocation: null,
                overPost: null
            });
        }

        this.setState(
        {
            postLocation: location,
            selectedPost: featurePost
        });

        // report selected post
        this.SelectedPost(featurePost);
        featurePost.properties['selected'] = true;
        if (this.selectedPostId > 0)
        {
            this.currentPosts.features.find(p=>p.properties.postId===this.selectedPostId).properties['selected'] = false;
        }
        this.selectedPostId = featurePost.properties.postId;

        // fly to Post
        let timeGlider = document.getElementById('TimeGlider');
        let heightOffset = 0;
        if (timeGlider) {
          heightOffset = timeGlider.offsetHeight / -2;
        }
        this.map.flyTo({
            center: location,
            offset: window.$isMobile ?
              [-((window.innerWidth / 2) - 65), -((window.innerHeight / 4) + 20)] :
              [0, heightOffset],
            zoom: featurePost.properties.postZoom
        });
    }

    mapMoved(location)
    {
        this.setState(
        {
            centerLocation: location
        });
    }

    CloseNewPost()
    {
        if (window.$isMobile) {
            this.enableControls();
            document.body.classList.remove('modal-open');
            document.body.classList.remove('no-timeline');
        }

        this.setState(
        {
            newLocation: null
        });

        // Refresh map size.
        this.map.resize();
    }

    CloseSelectedPost()
    {
        if (window.$isMobile)
        {
            this.enableControls();
            document.body.classList.remove('modal-open');
            document.body.classList.remove('no-timeline');
        }

        this.setState(
        {
            postLocation: null,
            selectedPost: null
        });
        if (this.selectedPostId > 0)
        {
            this.currentPosts.features.find(p=>p.properties.postId===this.selectedPostId).properties['selected'] = false;
        }
        this.selectedPostId = 0;

        // Refresh map size.
        this.map.resize();
    }

    goHome()
    {
        // get Coordinates
        navigator.geolocation.getCurrentPosition(position =>
        {
            let location =
            {
                lng: position.coords.longitude,
                lat: position.coords.latitude
            };

            // go to client Coordinates
            this.map.flyTo({center: location, zoom: 15});
        },
        () =>
        {
            let location =
            {
                lng: this.props.center.lng,
                lat: this.props.center.lat
            };

            // go to default Coordinates
            this.map.flyTo({center: location, zoom: this.props.center.zoom});
        });
    }

    goPost(post)
    {
        if (post)
        {
            // get Coordinates
            const location =
            {
                lng: post.geometry.coordinates[0],
                lat: post.geometry.coordinates[1]
            };

            this.setState(
            {
                postLocation: location,
                selectedPost: post
            });

            // report selected post
            this.SelectedPost(post);
            post.properties['selected'] = true;
            if (this.selectedPostId > 0)
            {
                this.currentPosts.features.find(p=>p.properties.postId===this.selectedPostId).properties['selected'] = false;
            }
            this.selectedPostId = post.properties.postId;

            // fly to Post
            let timeGlider = document.getElementById('TimeGlider');
            let heightOffset = 0;
            if (timeGlider) {
              heightOffset = timeGlider.offsetHeight / -2;
            }
            this.map.flyTo({
                center: location,
                offset: window.$isMobile ?
                  [-((window.innerWidth / 2) - 65), -((window.innerHeight / 4) + 20)] :
                  [0, heightOffset],
                zoom: post.properties.postZoom
            });
            if (window.$isMobile) {
              document.body.classList.add('modal-open');
              document.body.classList.add('no-timeline');
            }
        }
        else
        {
            // unselect post
            this.setState(
            {
                postLocation: null,
                selectedPost: null
            });

            // report unselected post
            this.SelectedPost(null);
            if (this.selectedPostId > 0)
            {
                this.currentPosts.features.find(p=>p.properties.postId===this.selectedPostId).properties['selected'] = false;
            }
            this.selectedPostId = 0;

            // Refresh map size.
            this.map.resize();
        }
    }

    // auxiliary dynamic choice of map popups anchor side
    recallAnchor(location, isBottom)
    {
        if (location) {
            const center = this.map.getCenter();
            const centerX = this.map.project([center.lng, center.lat]).x;
            const locationX = this.map.project([location.lng, location.lat]).x;
            if (window.$isMobile) return isBottom ? 'bottom-right' : 'top-left'

            return (locationX > centerX) ? 'right' : 'left';
        }
        else
        {
            return 'none';
        }
    }

    render()
    {
        const postAnchor = this.recallAnchor(this.state.postLocation);
        const newAnchor = this.recallAnchor(this.state.newLocation, this.state.clickIsBottom);
        const overAnchor = this.recallAnchor(this.state.overLocation);

        return (
            <div>
                <div className="map-container" ref={this.mapContainerRef} />
                <PopUpComponent location={this.state.postLocation} map={this.map} postComponent={<SelectedPostComponent map={this.map} location={this.state.postLocation} post={this.state.selectedPost} postReplies={this.props.currentPostReplies} userComunity={this.props.userComunity} callbackClose={this.CloseSelectedPost} confirmDialog={this.props.confirmDialog} goToPostCallback={this.goToPostCallback} anchor={postAnchor} />} isBalloon={true}  anchor={postAnchor} />
                <PreMarker location={this.state.newLocation} map={this.map} />
                <PopUpComponent location={this.state.newLocation} map={this.map} postComponent={<NewPostComponent location={this.state.newLocation} timeFrame={this.props.timeFrame} onTimeFrameDlgRequest={this.props.onTimeFrameDlgRequest} map={this.map} callbackClose={this.CloseNewPost} confirmDialog={this.props.confirmDialog} anchor={newAnchor} />} isBalloon={true} anchor={newAnchor} />
                <PopUpComponent location={this.state.overLocation} map={this.map} postComponent={<OverPostComponent location={this.state.overLocation} post={this.state.overPost} />} isBalloon={false} anchor={overAnchor} />
            </div>
        );
    }

}

export default Map;
