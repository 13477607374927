import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { isMobile } from "react-device-detect";
import homeButtonHovered from '../../../img/homeButton/home-btn-hovered.svg';
import homeButtonNormal from '../../../img/homeButton/home-btn-normal.svg';
import '../TopButtons.scss';

class HomeBtn extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            hovered: false
        }

        this.buttonEnter = this.buttonEnter.bind(this);
        this.buttonLeave = this.buttonLeave.bind(this);
        this.buttonClick = this.buttonClick.bind(this);
    }

    buttonEnter()
    {
        this.setState(
        {
            hovered: true,
        });
    }

    buttonLeave()
    {
        this.setState(
        {
            hovered: false,
        });
    }

    buttonClick()
    {
        this.props.onGoHome();
    }

    render()
    {
        return (

            <div className="navbar-button"
                onMouseEnter={this.buttonEnter}
                onMouseLeave={this.buttonLeave}
                onClick={this.buttonClick}
            >
                <Tippy content="Bring me home now" disabled={isMobile}>
                    <img src=
                        {
                            (this.state.hovered)
                            ? homeButtonHovered
                            : homeButtonNormal
                        }
                        alt="+"
                    />
                </Tippy>

            </div>
        );
    }
}

export default HomeBtn;


