import React from 'react';

import Button from '../Button/Button';

import './SignUpDialog.scss';

class SignUpDialog extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      Email: 'Email',
      EmailValid: false,

      Password: 'password',
      PasswordValid: false,

      Confirm: 'match password',
      ConfirmValid: false,

      changedEmail: false,
      changedPassword: false,
      changedConfirm: false
    };

    this.OnEmailChanged = this.OnEmailChanged.bind(this);
    this.OnPasswordChanged = this.OnPasswordChanged.bind(this);
    this.OnConfirmChanged = this.OnConfirmChanged.bind(this);
    this.OnEmailFocus = this.OnEmailFocus.bind(this);
    this.OnPasswordFocus = this.OnPasswordFocus.bind(this);
    this.OnConfirmFocus = this.OnConfirmFocus.bind(this);

    this.OnCancel = this.OnCancel.bind(this);
    this.OnSubmit = this.OnSubmit.bind(this);

    this.OnCancelConfirm = this.OnCancelConfirm.bind(this);
    this.OnCancelClose = this.OnCancelClose.bind(this);
  }

  doneSignUpDialog()
  {
    this.props.callbackDone();

    this.setState(
    {
      Email: 'Email',
      EmailValid: false,

      Password: 'password',
      PasswordValid: false,

      Confirm: 'match password',
      ConfirmValid: false,

      changedEmail: false,
      changedPassword: false,
      changedConfirm: false
    });
  }

  OnEmailChanged(e)
  {
    let newEmail = (e.target.value === null || e.target.value === undefined || e.target.value === ' ') ? '' : e.target.value;
    let emailRegExp = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    
    this.setState(
    {
      Email: newEmail,
      EmailValid: ((newEmail !== '') && emailRegExp.test(newEmail)),

      changedEmail: true
    });
  }

  OnEmailFocus(e)
  {
    if (!this.state.changedEmail || !this.state.EmailValid)
    {
      let newEmail = '';
      e.target.value = newEmail;

      this.setState(
      {
        Email: newEmail,
        EmailValid: false,
        changedEmail: true
      });
    }
  }

  OnPasswordChanged(e)
  {
    let newPassword = (e.target.value === null || e.target.value === undefined || e.target.value === ' ') ? '' : e.target.value;

    this.setState(
    {
      Password: newPassword,
      PasswordValid: (newPassword !== ''),

      changedPassword: true
    });
  }

  OnPasswordFocus(e)
  {
    if (!this.state.changedPassword || !this.state.PasswordValid)
    {
      let newPassword = '';
      e.target.value = newPassword;
      this.setState(
      {
        Password: newPassword,
        PasswordValid: false,
        changedPassword: true
      });
    }
  }

  OnConfirmChanged(e)
  {
    let newConfirm = (e.target.value === null || e.target.value === undefined || e.target.value === ' ') ? '' : e.target.value;

    this.setState(
    {
      Confirm: newConfirm,
      ConfirmValid: (newConfirm !== ''),

      changedConfirm: true
    });
  }

  OnConfirmFocus(e)
  {
    if (!this.state.changedConfirm || !this.state.ConfirmValid)
    {
      let newConfirm = '';
      e.target.value = newConfirm;
      this.setState(
      {
        Confirm: newConfirm,
        ConfirmValid: false,
        changedConfirm: true
      });
    }
  }

  OnSubmit()
  {
    if (this.state.EmailValid && this.state.PasswordValid && this.state.ConfirmValid && (this.state.Confirm === this.state.Password))
    {
      // sttepmt to sign user in
      this.props.onDoSignUp(this.state.Email, this.state.Password);

      this.doneSignUpDialog();
    }
    else
    {
      if (!this.state.EmailValid)
      {
        this.setState(
        {
          changedEmail: true,
          Email: 'Email'
        });
      }

      if (!this.state.PasswordValid)
      {
        this.setState(
        {
          changedPassword: true,
          Password: 'password'
        });
      }

      if ((!this.state.ConfirmValid) || (this.state.Confirm !== this.state.Password))
      {
        this.setState(
        {
          changedConfirm: true,
          Confirm: 'match password',
          ConfirmValid: false
        });
      }

    }
  }

  OnCancel()
  {
    if ((this.state.changedEmail && this.state.EmailValid) || (this.state.changedPassword && this.state.PasswordValid))
    {
      this.props.confirmDialog("Do you really want to cancel sign up?", this.OnCancelConfirm, this.OnCancelClose);
    }
    else
    {
      this.props.callbackCancel()
      this.doneSignUpDialog();
    }
  }

  OnCancelConfirm()
  {
    this.props.callbackCancel();
    this.doneSignUpDialog();
  }

  OnCancelClose()
  {
    // keep on state
  }

  render()
  {
    return (

      <div className="SignUpDialog" >

        <div className="SignUpUserName">
          {'Username: '+this.props.userName}
        </div>

        <div className="SignUpFormItem">
          <input
            className="SignUpInput"
            type="text"

            value={this.state.Email}
            spellCheck="false"

            style={{color: this.state.EmailValid ? 'black' : (this.state.changedEmail ? 'red' : 'gray')}}

            onChange={this.OnEmailChanged}
            onFocus={this.OnEmailFocus}
            onClick={this.OnEmailFocus}
          />
        </div>

        <div className="SignUpFormItem">
          <input
            className="SignUpInput"
            type={this.state.PasswordValid ? 'password' : 'text'}

            value={this.state.Password}

            style={{color: this.state.PasswordValid ? 'black' : (this.state.changedPassword ? 'red' : 'gray')}}

            onChange={this.OnPasswordChanged}
            onFocus={this.OnPasswordFocus}
            onClick={this.OnPasswordFocus}
          />
        </div>

        <div className="SignUpFormItem">
          <input
            className="SignUpInput"
            type={this.state.ConfirmValid ? 'password' : 'text'}

            value={this.state.Confirm}

            style={{color: this.state.ConfirmValid ? 'black' : (this.state.changedConfirm ? 'red' : 'gray')}}

            onChange={this.OnConfirmChanged}
            onFocus={this.OnConfirmFocus}
            onClick={this.OnConfirmFocus}
          />
        </div>

        <Button type="close" position="top-right-1" callbackAction={this.OnCancel} tip={'Cancel'} alt="X" />
        <Button type="submit" position="Button--next right-bottom-2" callbackAction={this.OnSubmit} tip={'Sign Up'} alt=">" />

      </div>

    );
  }
}

export default SignUpDialog;
