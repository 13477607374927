import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { isMobile } from "react-device-detect";
import AboutDialog from './AboutDialog/AboutDialog';

import infoButtonHovered from '../../../img/infoButton/info-btn-hovered.svg';
import infoButtonNormal from '../../../img/infoButton/info-btn-normal.svg';
import '../TopButtons.scss';

class InfoBtn extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            hovered: false,
            dialogOpen: false
        }

        this.buttonEnter = this.buttonEnter.bind(this);
        this.buttonLeave = this.buttonLeave.bind(this);
        this.buttonClick = this.buttonClick.bind(this);
    }

    buttonEnter()
    {
        this.setState(
        {
            hovered: true,
        });
    }

    buttonLeave()
    {
        if (!this.state.dialogOpen)
        {
            this.setState(
            {
                hovered: false,
            });
        }
    }

    buttonClick()
    {
        this.setState(
        {
            dialogOpen: !this.state.dialogOpen
        },
            ()=>
            {
                if (!this.state.dialogOpen)
                {
                    this.setState(
                    {
                        hovered: false,
                    });
            }
        });
    }

    render()
    {
        return (

            <div className="navbar-button"
                onMouseEnter={this.buttonEnter}
                onMouseLeave={this.buttonLeave}
                onClick={this.buttonClick}
            >
                <Tippy content="About" disabled={isMobile} disabled={window.$isMobile}>
                    <img src=
                        {
                            (this.state.hovered)
                            ? infoButtonHovered
                            : infoButtonNormal
                        }
                        alt="i"
                    />
                </Tippy>

                {this.state.dialogOpen && <AboutDialog showHowToCallback={this.props.showHowToCallback} />}

            </div>
        );
    }
}

export default InfoBtn;


