import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { isMobile } from "react-device-detect";

//(img)import signedButtonHovered from '../../../img/signedButton/signed-btn-hovered.svg';
//(img)import signedButtonNormal from '../../../img/signedButton/signed-btn-normal.svg';
import './SignedBtn.css';

class SignedBtn extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state =
        {
            hovered: false
        }

        this.buttonEnter = this.buttonEnter.bind(this);
        this.buttonLeave = this.buttonLeave.bind(this);
        this.buttonClick = this.buttonClick.bind(this);
        this.OnSignOutConfirm = this.OnSignOutConfirm.bind(this);
        this.OnSignOutCancel = this.OnSignOutCancel.bind(this);
    }

    buttonEnter()
    {
        this.setState(
        {
            hovered: true,
        });
    }

    buttonLeave()
    {
        this.setState(
        {
            hovered: false,
        });
    }

    buttonClick()
    {
        // Confirm to Sign out...
        this.props.confirmDialog("Do you really want to Sign out?", this.OnSignOutConfirm, this.OnSignOutCancel);
    }

    OnSignOutConfirm()
    {
        //procedd to sign out
        this.props.onSignOut();
    }

    OnSignOutCancel()
    {
      // keep on state
    }

    render()
    {
        return (

            <Tippy content="Sign out" disabled={isMobile}>

                <div className={'signed-button '+(this.state.hovered ? 'hovered' : 'normal')}//(img)className="signed-button"
                    onMouseEnter={this.buttonEnter}
                    onMouseLeave={this.buttonLeave}
                    onClick={this.buttonClick}
                >

                    {/*(img)<img src=
                        {
                            (this.state.hovered)
                            ? signedButtonHovered
                            : signedButtonNormal
                        }
                        alt="<"
                    />*/}

                    <div className={'UserName '+(this.state.hovered ? 'hovered' : 'normal')}>
                        {this.props.userName}
                    </div>

                </div>

            </Tippy>
        );
    }
}

export default SignedBtn;


