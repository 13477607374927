import React from 'react';
import './Attributions.css';

const Attributions = () => (
    <div>
      <div className="attributions">
        <div>
          <a className="attributions__elements" href="https://urbanoid.com/" target="_blank">&#169; urbanoid </a>
          <a className="attributions__elements" href="./termsofuse.html" target="_blank">Terms of use</a>
        </div>
      </div>
    </div>
);

export default Attributions;