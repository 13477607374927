import React from 'react';

import dateEpoch from '../../../../serviceclient/dateEpoch';

import './TimeFrameLabel.scss';

class TimeFrameLabel extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      highlight: false
    }

    this.OnMouseEnter = this.OnMouseEnter.bind(this);
    this.OnMouseLeave = this.OnMouseLeave.bind(this);
  }

  OnMouseEnter()
  {
    this.setState(
    {
      highlight: true
    });
  }

  OnMouseLeave()
  {
    this.setState(
      {
        highlight: false
      });
  }

  render()
  {
    return (
      <div className={'TimeFrameLabel '+this.props.className}
        onMouseEnter={this.OnMouseEnter}
        onMouseLeave={this.OnMouseLeave}
        onClick={this.props.onClick}
      >

        {dateEpoch.orderedStringDate(this.props.time)}

      </div>
    );
  }
}

export default TimeFrameLabel;