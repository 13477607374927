import React from 'react';

import dateEpoch from '../../../serviceclient/dateEpoch';

import TimeScale from '../../Timeglider/TimeLine/TimeScale/TimeScale';

import '../../Timeglider/TimeLine/TimeLine.scss';

class TimeLine extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      width: 0
    };

    this.timeStepRange = dateEpoch.msHour;
    this.TimeGlide = this.props.callbackTimeGliding;

    this.timelineClicked = this.timelineClicked.bind(this);
    this.timelineMouseDown = this.timelineMouseDown.bind(this);
    this.timelineMouseMove = this.timelineMouseMove.bind(this);
    this.timelineMouseLeave = this.timelineMouseLeave.bind(this);
    this.timelineMouseUp = this.timelineMouseUp.bind(this);
    this.timelineWheel = this.timelineWheel.bind(this);
    this.timelineTouchStart = this.timelineTouchStart.bind(this);
    this.timelineTouchMove = this.timelineTouchMove.bind(this);
    this.timelineTouchCancel = this.timelineTouchCancel.bind(this);
    this.timelineTouchEnd = this.timelineTouchEnd.bind(this);

    this.swiping = false;
    this.swipeX = 0;
    this.swipeY = 0;
  }

  componentDidMount()
  {
    this.setState(
    {
      width: this.container.parentElement.getBoundingClientRect().width
    });

    window.addEventListener('resize', this.resizedTimeline.bind(this));

  }

  // event listener to get new width on resize
  resizedTimeline()
  {
    this.setState(
    {
      width: window.innerWidth
    });
  }

  // timeline interaction handlers

  timelineClicked(e)
  {
    let glideInfo =
    {
      centerAt: e.clientX / this.state.width
    }

    this.TimeGlide('center', glideInfo);
  }

  timelineMouseDown(e)
  {
    this.swipeX = e.clientX;
    this.swipeY = e.clientY;
    this.swiping = true;
  }

  timelineMouseMove(e)
  {
    if (this.swiping)
    {
      let swipingX = e.clientX;
      let swipingY = e.clientY;

      let swipeDY = swipingY - this.swipeY;
      let swipeDX = swipingX - this.swipeX;

      if (Math.abs(swipeDY) >= Math.abs(swipeDX))
      {
        // drag Vertical units factor (5)
        let mouse_vertical_move_factor = 5;

        let steps = Math.floor(Math.abs(swipeDY)/mouse_vertical_move_factor);
        let offset = steps * this.timeStepRange;

        let glideInfo =
        {
          offset: offset
        }

        if (swipeDY < 0)
        {
          this.TimeGlide('zoomin', glideInfo);
        }
        if (swipeDY > 0)
        {
          this.TimeGlide('zoomout', glideInfo);
        }
      }
      else
      {
        // drag Horiontal units factor (20)
        let mouse_horizontal_move_factor = 20;

        let steps = Math.floor(Math.abs(swipeDX)/mouse_horizontal_move_factor);
        let offset = steps * this.timeStepRange;

        let glideInfo =
        {
          offset: offset
        }

        if (swipeDX < 0)
        {
          this.TimeGlide('future', glideInfo);
        }
        if (swipeDX > 0)
        {
          this.TimeGlide('past', glideInfo);
        }
      }
    }
  }

  timelineMouseLeave()
  {
    this.swiping = false;
  }

  timelineMouseUp(e)
  {
    this.swiping = false;
  }

  timelineWheel(e)
  {
    // wheel/pad Scroll units factor (touchpad(1) mousewheel(50))
    let wheel_pad_factor = (Math.abs(e.deltaY) < 100) ? 1 : 50;

    let steps = Math.floor(Math.abs(e.deltaY)/wheel_pad_factor);
    let offset = steps * this.timeStepRange;

    let glideInfo =
    {
      offset: offset
    }

    if (e.deltaY < 0)
    {
      this.TimeGlide('zoomin', glideInfo);
    }
    if (e.deltaY > 0)
    {
      this.TimeGlide('zoomout', glideInfo);
    }

  }

  timelineTouchStart(e)
  {
    this.swipeX = e.touches[0].clientX;
    this.swipeY = e.touches[0].clientY;
    this.swiping = true;
  }

  timelineTouchMove(e)
  {
    if (this.swiping)
    {
      let swipingX = e.touches[0].clientX;
      let swipingY = e.touches[0].clientY;

      let swipeDY = swipingY - this.swipeY;
      let swipeDX = swipingX - this.swipeX;

      if (Math.abs(swipeDY) >= Math.abs(swipeDX))
      {
        // swipe Vertical units factor (10)
        let touch_vertical_swipe_factor = 10;

        let steps = Math.floor(Math.abs(swipeDY)/touch_vertical_swipe_factor);
        let offset = steps * this.timeStepRange;

        let glideInfo =
        {
          offset: offset
        }

        if (swipeDY < 0)
        {
          this.TimeGlide('zoomin', glideInfo);
        }
        if (swipeDY > 0)
        {
          this.TimeGlide('zoomout', glideInfo);
        }
      }
      else
      {
        // swipe Horizontal units factor (20)
        let touch_horizontal_swipe_factor = 20;

        let steps = Math.floor(Math.abs(swipeDX)/touch_horizontal_swipe_factor);
        let offset = steps * this.timeStepRange;

        let glideInfo =
        {
          offset: offset
        }

        if (swipeDX < 0)
        {
          this.TimeGlide('future', glideInfo);
        }
        if (swipeDX > 0)
        {
          this.TimeGlide('past', glideInfo);
        }
      }
    }
  }

  timelineTouchCancel(e)
  {
    this.swiping = false;
  }

  timelineTouchEnd(e)
  {
    this.swiping = false;
  }

  render()
  {
    // intermediate render to setup ref to access and get width
    return (
      <div ref={el => (this.container = el)} >
        {this.state.width && this.renderTimeline()}
      </div>
    );
  }

  renderTimeline()
  {
    // actual component render with proper value of width

    const epochFrom = dateEpoch.dateToEpoch(this.props.timeRange.timeFrom);
    const epochTo = dateEpoch.dateToEpoch(this.props.timeRange.timeTo);

    let HourTicksCount = dateEpoch.epochHoursIn(epochFrom, epochTo);
    let DayTicksCount = dateEpoch.epochDaysIn(epochFrom, epochTo);
    let MonthTicksCount = dateEpoch.epochMonthsIn(epochFrom, epochTo);
    let YearTicksCount = dateEpoch.epochYearsIn(epochFrom, epochTo);
    let DecadeTicksCount = dateEpoch.epochDecadesIn(epochFrom, epochTo);
    let CenturyTicksCount = dateEpoch.epochCenturiesIn(epochFrom, epochTo);

    const minTickSpace = 5;
    let minTicksCount = Math.floor(this.state.width / minTickSpace);

    let ticksCount = HourTicksCount;
    let minTickLevel = 'H';

    if (HourTicksCount <= minTicksCount)
    {
      ticksCount = HourTicksCount;
      minTickLevel = 'H';
      this.timeStepRange = dateEpoch.msHour;
    }
    else
    {
      if (DayTicksCount <= minTicksCount)
      {
        ticksCount = DayTicksCount;
        minTickLevel = 'D';
        this.timeStepRange = dateEpoch.msDay;
      }
      else
      {
        if (MonthTicksCount <= minTicksCount)
        {
          ticksCount = MonthTicksCount;
          minTickLevel = 'M';
          this.timeStepRange = dateEpoch.msMonth;
        }
        else
        {
          if (YearTicksCount <= minTicksCount)
          {
            ticksCount = YearTicksCount;
            minTickLevel = 'Y';
            this.timeStepRange = dateEpoch.msYear;
          }
          else
          {
            if (DecadeTicksCount <= minTicksCount)
            {
              ticksCount = DecadeTicksCount;
              minTickLevel = 'X';
              this.timeStepRange = dateEpoch.msDecade;
            }
            else
            {
              ticksCount = CenturyTicksCount;
              minTickLevel = 'C';
              this.timeStepRange = dateEpoch.msCentury;
            }
          }
        }
      }
    }

    let tickSpace = Math.floor(this.state.width / ticksCount);
    let minTickLabelSpace = { 'H':30, 'D':15, 'M':18, 'Y':28, 'X':28, 'C':30 };

    let epochSpan = epochTo - epochFrom;
    let epochSegment = Math.floor(epochSpan / ticksCount);

    let timeTicks = [];

    let fromExactEpoch = dateEpoch.nextExactTime(minTickLevel, epochFrom);

    let currentTickEpoch = fromExactEpoch;
    while(currentTickEpoch <= epochTo)
    {
      let timeTickInfo = dateEpoch.timeTickInfo(currentTickEpoch);

      if ((timeTickInfo.level === minTickLevel) && (tickSpace < minTickLabelSpace[minTickLevel]))
      {
        timeTickInfo.text = '';
      }

      timeTickInfo.position = Math.floor((Math.abs(currentTickEpoch-epochFrom) / epochSpan) * this.state.width);

      timeTicks.push(timeTickInfo);

      currentTickEpoch = dateEpoch.nearExactTime(minTickLevel, currentTickEpoch + epochSegment);//currentTickEpoch += epochSegment;
    }

    return (
      <div className="TimeLine"
          onClick={this.timelineClicked}
          onMouseDown={this.timelineMouseDown}
          onMouseMove={this.timelineMouseMove}
          onMouseLeave={this.timelineMouseLeave}
          onMouseUp={this.timelineMouseUp}
          onWheel={this.timelineWheel}
          onTouchStart={this.timelineTouchStart}
          onTouchMove={this.timelineTouchMove}
          onTouchCancel={this.timelineTouchCancel}
          onTouchEnd={this.timelineTouchEnd}
        >

        <TimeScale className="aboveAxis" timeTicks={timeTicks} />

        <div className="timeAxis" />

      </div>
    );
  }

}

export default TimeLine;