import Fingerprint2 from '../lib/fingerprint2'

// User environment ID not available flag string constant 
const UNSET = 'UNSET';

// x-ode User definition
class User
{
    // User environment ID (fingerptint)
    userEnvID;

    // User Id
    userId;

    // User name code
    userName;

    // Signed/Anomimity modes flag
    signedMode;

    // constructor and property definition 
    constructor(userEnvID)
    {
        this.userEnvID = userEnvID;
        this.signedMode = false;
    }

    // check if User initialized 
    isReady()
    {
        return this.userEnvID !== UNSET;
    }
  
    // Get value for User environment ID using Fingerprint2.
    // then Set the value and then invoke subsequent supplied callback method
    getUserEnvironmentID(setReadyCallback)
    {
        let options = 
        {
            excludes: // Exclude non stable components to gain ID accuracy
            {
                hardwareConcurrency: true,
                screenResolution: true,
                timezone: true,
                plugins: true,
                canvas: true,
                webgl: true,
                webglVendorAndRenderer: true,
                adBlock: true,
                fonts: true,
                audio: true,
                enumerateDevices: true
            }
        };
    
        // Collect fingerprint in promise fashion
        Fingerprint2.getPromise(options)
        .then((components) =>
        {
            // Collect succesfull/fullfiled promise
            let values = components.map(function(component) { return component.value });
            this.userEnvID = Fingerprint2.x64hash128(values.join(''), 31);

            // report ready
            setReadyCallback(true);
        })
        .catch(() =>
        {
            // Collect error
            this.userEnvID = UNSET;

            // report not ready
            setReadyCallback(false);
        });
    }
}

// x-ode User instance 
const xodeUser = new User(UNSET);
export default xodeUser;
