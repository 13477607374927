import React from 'react';

import Button from '../Button/Button';

import './InfoDialog.css';

class InfoDialog extends React.Component
{
  constructor(props)
  {
    super(props);

    this.onDoneDialog = this.props.callbackDone;

    this.onClose = this.onClose.bind(this);
  }

  onClose()
  {
    this.onDoneDialog();
  }

  render()
  {
    return (

      <div className="InfoDialog">

        <div className="infoMessage">{this.props.infoMessage}</div>

        <Button type="close" position="top-right-1" callbackAction={this.onClose} tip={'Close'} alt="X" />

      </div>

    );
  }
}

export default InfoDialog;