import React from 'react';

import './ConnectingLoading.css';

class ConnectingLoading extends React.Component
{
  render()
  {
    return (
      <div className="ConnectingLoading" >
        <div className="logo-blink" />
        <div className="logo" />
      </div>
    );
  }
}

export default ConnectingLoading;