import React from 'react';

import './ModalDialogBackground.css';

class ModalDialogBackground extends React.Component
{
  render()
  {
    return (

      <div className="ModalDialogBackground" />

    );
  }
}

export default ModalDialogBackground;