import React from 'react';

import PostTitleInfo from '../PostTitleInfo/PostTitleInfo';

import './OverPostComponent.css';

class OverPostComponent extends React.Component
{
  render()
  {
    return (

      <div className="OverPostComponent">

        <PostTitleInfo title={this.props.post.properties.title} epochFrom={this.props.post.properties.epochFrom} epochTo={this.props.post.properties.epochTo} />

      </div>

    );
  }
}

export default OverPostComponent;