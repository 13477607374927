import React from 'react';

import dateEpoch from '../../../serviceclient/dateEpoch';
import TimeFrameLabel from '../../Timeglider/TimeFrame/TimeFrameLabel/TimeFrameLabel';
import '../../Timeglider/TimeFrame/TimeFrame.scss';

class TimeFrame extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      width: 0
    };

    this.timeFrom = this.props.timeFrame.timeFrom; // TimeFrame datetime from bracket
    this.timeTo = this.props.timeFrame.timeTo; // TimeFrame datetime to bracket

    this.frameFrom = 0; // TimeFrame pixels from bracket
    this.frameTo = 0; // TimeFrame pixels from bracket

    this.leftWidth = 0; // pixels left to TimeFrame
    this.rightWidth = 0; // pixels right to TimeFrame

    this.fittedTimeFrame = false;

    this.TimeFrameSet = this.props.callbackTimeFraming;

    this.timeframeUpdated = this.timeframeUpdated.bind(this);
    this.props.setTimeFrameChanged(this.timeframeUpdated);

    this.timeFromFallback = null; // Fallback TimeFrame datetime from bracket
    this.timeToFallback = null; // Fallback TimeFrame datetime to bracket

    this.leftBracketMouseDown = this.leftBracketMouseDown.bind(this);
    this.leftBracketMouseMove = this.leftBracketMouseMove.bind(this);
    this.leftBracketMouseLeave = this.leftBracketMouseLeave.bind(this);
    this.leftBracketMouseUp = this.leftBracketMouseUp.bind(this);
    this.leftBracketTouchStart = this.leftBracketTouchStart.bind(this);
    this.leftBracketTouchMove = this.leftBracketTouchMove.bind(this);
    this.leftBracketTouchCancel = this.leftBracketTouchCancel.bind(this);
    this.leftBracketTouchEnd = this.leftBracketTouchEnd.bind(this);

    this.rightBracketMouseDown = this.rightBracketMouseDown.bind(this);
    this.rightBracketMouseMove = this.rightBracketMouseMove.bind(this);
    this.rightBracketMouseLeave = this.rightBracketMouseLeave.bind(this);
    this.rightBracketMouseUp = this.rightBracketMouseUp.bind(this);
    this.rightBracketTouchStart = this.rightBracketTouchStart.bind(this);
    this.rightBracketTouchMove = this.rightBracketTouchMove.bind(this);
    this.rightBracketTouchCancel = this.rightBracketTouchCancel.bind(this);
    this.rightBracketTouchEnd = this.rightBracketTouchEnd.bind(this);
    this.fixBracketPosition = this.fixBracketPosition.bind(this);
    this.leftDrag = false;
    this.leftDragXo = 0;

    this.rightDrag = false;
    this.rightDragXo = 0;
  }

  componentDidMount()
  {
    this.setState(
    {
      width: this.container.parentElement.getBoundingClientRect().width
    });

    window.addEventListener('resize', this.resizedTimeFrame.bind(this));

  }

  // event listener to get new width on resize
  resizedTimeFrame()
  {
    this.setState(
    {
      width: window.innerWidth
    });
  }

  // Brackets interaction handlers

  //left bracket
  leftBracketMouseDown(e)
  {
    this.leftDragXo = e.clientX;
    this.leftDrag = true;
  }

  leftBracketMouseMove(e)
  {
    if (this.leftDrag)
    {
      let X = e.clientX;

      this.moveLeftBracket(X);
    }
  }

  leftBracketMouseLeave()
  {
    this.leftDrag = false;
  }

  leftBracketMouseUp(e)
  {
    this.leftDrag = false;
  }

  leftBracketTouchStart(e)
  {
    this.leftDragXo = e.touches[0].clientX;
    this.leftDrag = true;
  }

  leftBracketTouchMove(e)
  {
    if (this.leftDrag)
    {
      let X = e.touches[0].clientX;

      this.moveLeftBracket(X);
    }
  }

  leftBracketTouchCancel(e)
  {
    this.leftDrag = false;
  }

  leftBracketTouchEnd(e)
  {
    this.leftDrag = false;
  }

  moveLeftBracket(X)
  {
    let DX = X - this.leftDragXo;

    let frameFrom = this.frameFrom + DX;

    let epochRangeFrom = dateEpoch.dateToEpoch(this.props.timeRange.timeFrom);
    let epochRangeTo = dateEpoch.dateToEpoch(this.props.timeRange.timeTo);
    let rangeSpan = epochRangeTo - epochRangeFrom;

    const limitfactor = 10;
    let limitWidth = this.state.width/limitfactor;

    let minTimeWidth = (dateEpoch.timeFrameMin() * this.state.width) / rangeSpan;
    let limitFrom = this.frameTo - ((minTimeWidth > limitWidth) ? minTimeWidth : limitWidth);

    if ((frameFrom > limitWidth) && (frameFrom < limitFrom))
    {
      let epochFrom = epochRangeFrom + (frameFrom * rangeSpan) / this.state.width;
      epochFrom = (DX > 0) ? dateEpoch.nextExactTime('H', epochFrom) : dateEpoch.prevExactTime('H', epochFrom);
      if (epochFrom <= epochRangeFrom) { epochFrom += dateEpoch.msHour };

      frameFrom = ((epochFrom - epochRangeFrom) * this.state.width) / rangeSpan;

      this.timeFrom = dateEpoch.epochToDate(epochFrom);
      this.frameFrom = frameFrom;
      this.leftWidth = this.frameFrom;

      this.leftDragXo = X;

      this.TimeFrameSet(this.timeFrom, this.timeTo);
    }
    else
    {
      this.leftDrag = false;
    }
  }

  //right bracket
  rightBracketMouseDown(e)
  {
    this.rightDragXo = e.clientX;
    this.rightDrag = true;
  }

  rightBracketMouseMove(e)
  {
    if (this.rightDrag)
    {
      let X = e.clientX;

      this.moveRightBracket(X);
    }
  }

  rightBracketMouseLeave()
  {
    this.rightDrag = false;
  }

  rightBracketMouseUp(e)
  {
    this.rightDrag = false;
  }

  rightBracketTouchStart(e)
  {
    this.rightDragXo = e.touches[0].clientX;
    this.rightDrag = true;
  }

  rightBracketTouchMove(e)
  {
    if (this.rightDrag)
    {
      let X = e.touches[0].clientX;

      this.moveRightBracket(X);
    }
  }

  rightBracketTouchCancel(e)
  {
    this.rightDrag = false;
  }

  rightBracketTouchEnd(e)
  {
    this.rightDrag = false;
  }

  moveRightBracket(X)
  {
    let DX = X - this.rightDragXo;

    let frameTo = this.frameTo + DX;

    let epochRangeFrom = dateEpoch.dateToEpoch(this.props.timeRange.timeFrom);
    let epochRangeTo = dateEpoch.dateToEpoch(this.props.timeRange.timeTo);
    let rangeSpan = epochRangeTo - epochRangeFrom;

    const limitfactor = 10;
    let limitWidth = this.state.width/limitfactor;

    let minTimeWidth = (dateEpoch.timeFrameMin() * this.state.width) / rangeSpan;
    let limitTo = this.frameFrom + ((minTimeWidth > limitWidth) ? minTimeWidth : limitWidth);

    if ((frameTo < (this.state.width-limitWidth)) && (frameTo > limitTo))
    {
      let epochTo = epochRangeFrom + (frameTo * rangeSpan) / this.state.width;
      epochTo = (DX > 0) ? dateEpoch.nextExactTime('H', epochTo) : dateEpoch.prevExactTime('H', epochTo);
      if (epochTo >= epochRangeTo) { epochTo -= dateEpoch.msHour };

      frameTo = ((epochTo - epochRangeFrom) * this.state.width) / rangeSpan;

      this.timeTo = dateEpoch.epochToDate(epochTo);
      this.frameTo = frameTo;
      this.rightWidth = this.state.width - this.frameTo;

      this.rightDragXo = X;

      this.TimeFrameSet(this.timeFrom, this.timeTo);
    }
    else
    {
      this.rightDrag = false;
    }
  }

    // Position the brackets according to the dates.
  fixBracketPosition() {
    // Time range in the window.
    let epochRangeFrom = dateEpoch.dateToEpoch(this.props.timeRange.timeFrom);
    let epochRangeTo = dateEpoch.dateToEpoch(this.props.timeRange.timeTo);

    // Time range from bracket to bracket.
    let epochFrameFrom = dateEpoch.dateToEpoch(this.props.timeFrame.timeFrom);
    let epochFrameTo = dateEpoch.dateToEpoch(this.props.timeFrame.timeTo);

    // Total date range in the window.
    let rangeSpan = epochRangeTo - epochRangeFrom;
    this.frameFrom = ((epochFrameFrom - epochRangeFrom) * this.state.width) / rangeSpan;
    this.frameTo = ((epochFrameTo - epochRangeFrom) * this.state.width) / rangeSpan;

    this.leftWidth = this.frameFrom;

    this.rightWidth = this.state.width - this.frameTo;
    this.leftDragX = this.frameFrom;
    this.rightDragX = this.frameTo;
    this.forceUpdate();
  }

  //check if TimeFrame changed due to timegliding
  timeframeUpdated()
  {
      // trigger time frame changed
      if (this.fittedTimeFrame)
      {
        if (!this.timeFrom || !this.timeTo)
        {
          this.timeFrom = this.timeFromFallback;
          this.timeTo = this.timeToFallback;
        }
        this.TimeFrameSet(this.timeFrom, this.timeTo);

        this.timeFromFallback = this.timeFrom;
        this.timeToFallback = this.timeTo;
      }
  }

  // set TimeFrame values and current representation
  calcTimeFrame()
  {
    //calculate TimeFame
    // Time range in the window.
    let epochRangeFrom = dateEpoch.dateToEpoch(this.props.timeRange.timeFrom);
    let epochRangeTo = dateEpoch.dateToEpoch(this.props.timeRange.timeTo);

    // Time range from bracket to bracket.
    let epochFrameFrom = dateEpoch.dateToEpoch(this.props.timeFrame.timeFrom);
    let epochFrameTo = dateEpoch.dateToEpoch(this.props.timeFrame.timeTo);

    // Total date range in the window.
    let rangeSpan = epochRangeTo - epochRangeFrom;

    let frameFrom;
    let frameTo;

    const limitfactor = 10;
    let limitWidth = this.state.width/limitfactor;

    let minTimeWidth = (dateEpoch.timeFrameMin() * this.state.width) / rangeSpan;
    let limitFrom = this.frameTo - ((minTimeWidth > limitWidth) ? minTimeWidth : limitWidth);
    let limitTo = this.frameFrom + ((minTimeWidth > limitWidth) ? minTimeWidth : limitWidth);
    this.leftDragX = limitWidth;
    this.rightDragX = (this.state.width-limitWidth);
    frameFrom = this.leftDragX;
    frameTo = this.rightDragX;

    //check TimeFame fit to TimeRange

    this.fittedTimeFrame = false;

    if (frameFrom < limitWidth)
    {
      frameFrom = limitWidth;

      this.fittedTimeFrame = true;
    }
    if ((this.state.width - frameTo) <= (limitWidth + 10))
    {
      frameTo = this.state.width - limitWidth;

      this.fittedTimeFrame = true;
    }

    let frameWidth = frameTo - frameFrom;
    if (minTimeWidth > limitWidth) {limitWidth = minTimeWidth;}

    if (frameWidth < limitWidth)
    {
      let fitWidth = (limitWidth - frameWidth);

      let marginFrom = frameFrom - limitWidth;
      if (marginFrom < 0) {marginFrom = 0;}

      let marginTo = (this.state.width - frameTo) - limitWidth
      if (marginTo < 0) {marginTo = 0;}

      let marginTotal = marginFrom + marginTo;
      if (marginTotal > 0)
      {
        let factorFrom = marginFrom / marginTotal;
        let factorTo = marginTo / marginTotal;

        frameFrom = frameFrom - (fitWidth * factorFrom);
        frameTo = frameTo + (fitWidth * factorTo);

        this.fittedTimeFrame = true;
      }
    }

    // set TimeFrame values

    if (this.fittedTimeFrame)
    {
      let epochFrom = epochRangeFrom + (frameFrom * rangeSpan) / this.state.width;
      epochFrom = dateEpoch.prevExactTime('H', epochFrom);
      if (epochFrom <= epochRangeFrom) { epochFrom += dateEpoch.msHour };

      frameFrom = ((epochFrom - epochRangeFrom) * this.state.width) / rangeSpan;
      this.timeFrom = dateEpoch.epochToDate(epochFrom);

      let epochTo = epochRangeFrom + (frameTo * rangeSpan) / this.state.width;
      epochTo = dateEpoch.nextExactTime('H', epochTo);
      if (epochTo >= epochRangeTo) { epochTo -= dateEpoch.msHour };

      frameTo = ((epochTo - epochRangeFrom) * this.state.width) / rangeSpan;
      this.timeTo = dateEpoch.epochToDate(epochTo);
    }

    this.frameFrom = frameFrom;
    this.frameTo = frameTo;

    this.leftWidth = this.frameFrom;
    this.rightWidth = this.state.width - this.frameTo;
  }

  render()
  {
    // intermediate render to setup ref to access and get width
    return (
      <div ref={el => (this.container = el)} >
        {this.state.width && this.renderTimeFrame()}
      </div>
    );
  }

  renderTimeFrame()
  {
    // actual component render with proper value of width

    this.calcTimeFrame();

    return (

      <div className="TimeFrame" >

        <div className="TimeFrameLeft" style={{width: this.leftWidth+'px'}} >

          <TimeFrameLabel className="From" time={this.props.timeFrame.timeFrom} onClick={this.props.timeLabelClicked} />

        </div>

        <div className="TimeFrameBrackets">
          <img src={'/img/bracket-red-left.svg'} className="LeftBracket" alt="[" onMouseDown={(e) => {e.preventDefault();}} />
          <img src={'/img/bracket-red-right.svg'} className="RightBracket" alt="[" onMouseDown={(e) => {e.preventDefault();}} />
        </div>

        <div className="TimeFrameRight" style={{width: this.rightWidth+'px'}} >

          <TimeFrameLabel className="To" time={this.props.timeFrame.timeTo} onClick={this.props.timeLabelClicked} />

        </div>

        <div className="bracketHandler" style={{left: (this.frameFrom-30)+'px'}}
        />

        <div className="bracketHandler" style={{left: (this.frameTo-30)+'px'}}

        />

      </div>

    );
  }
}

export default TimeFrame;
