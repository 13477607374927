
// x-ode WebSocket API definition 
class WSapi
{
    // x-ode WebSocket service url
    connectionUrl;

    // x-ode session authorization token
    sessionInfo;

    // WebSocket for connection
    socket;

    // x-ode WebSocket service connection ID
    connID;

    // constructor and property definition
    constructor(wsUrl)
    {
        this.connectionUrl = wsUrl;

        this.connID = '';
    }

    // check if WebSocket ready
    get isReady()
    {
        return this.socket.readyState === WebSocket.OPEN && this.connID !== '';
    }

    // Connection to x-ode WebSocket
    Connect(sessionInfo, client)
    {
        //keep session auth
        this.sessionInfo = sessionInfo;

        // Connect
        this.socket = new WebSocket(this.connectionUrl + "?" + this.sessionInfo.authToken + ':' + this.sessionInfo.userId);
        
        // Connection opened
        this.socket.onopen = function(event) 
        {
            //alert('Connection opened');
        };

        // Connection closed
        this.socket.onclose = function(event) 
        {
            //alert('Connection closed');
            this.connID = '';
        };

        // Connection error
        this.socket.onerror = function(event) 
        {
            //alert('Connection error');
        };

        // Data from server
        this.socket.onmessage = function(event) 
        {
            let message = event.data;

            let Message = JSON.parse(message);

            // process received message
            switch(Message.messageType) 
            {
                // x-ode websocket connection ID
                case xodeMessageType.CONNECT_ID:
                {
                    this.connID = Message.connectionID;

                    client.onConnected();
                }
                break;

                // x-ode login ID
                case xodeMessageType.LOGIN_CONFIRM:
                {
                    // user login not used thru this ws api
                    // initial and anonimous log in is currently done thru rest api              
                }
                break;

                // x-ode authentication Token
                case xodeMessageType.SIGNIN_CONFIRM:
                {
                    // user authentication
                    client.onSignInConfirm(Message.success, Message.sessionInfo);
                }
                break;

                // x-ode sign out
                case xodeMessageType.LOGOUT_CONFIRM:
                {
                    // signed user logout
                    client.onSignOutConfirm(Message.userId);
                }
                break;

                // x-ode signed up user authentication Token
                case xodeMessageType.SIGNUP_CONFIRM:
                {
                    if (Message.success)
                    {
                        //keep session info
                        this.sessionInfo = Message.sessionInfo;
                    }

                    // user authentication
                    client.onSignUpConfirm(Message.success, Message.sessionInfo);
                }
                break;
                                    
                // list of x-ode posts as feature collection
                case xodeMessageType.FEATUREPOSTSLIST_RESPONSE:
                {
                    // x-ode posts available for user
                    client.onCurrentPosts(Message.featurePosts);
                }
                break;

                // x-ode post added
                case xodeMessageType.ADDNEWPOST_CONFIRM:
                {
                    // added post Id on x-ode
                    client.onPostAdded(Message.postId);
                }
                break;

                // x-ode post added
                case xodeMessageType.NEWPOST_NOTICE:
                {
                    // new added post Id on x-ode
                    client.onPostAddedNotice(Message.postId);
                }
                break;

                // x-ode post deleted
                case xodeMessageType.DELETEPOST_CONFIRM:
                {
                    // deleted post Id and success flag
                    client.onPostDeleted(Message.postId, Message.success);
                }
                break;

                // x-ode post deleted notice
                case xodeMessageType.DELETEPOST_NOTICE:
                {
                    // noticed deleted post Id
                    client.onPostDeletedNotice(Message.postId);
                }
                break;

                // x-ode post replied
                case xodeMessageType.REPLIEDPOST_CONFIRM:
                {
                    // post Id and added reply message Id on x-ode
                    client.onPostReplied(Message.postId, Message.messageId);
                }
                break;

                // x-ode post replied notice
                case xodeMessageType.REPLIEDPOST_NOTICE:
                {
                    // added reply message info an replied post, on x-ode
                    client.onPostRepliedNotice(Message.postId, Message.userId, Message.messageId);
                }
                break;

                // x-ode post reply messages response
                case xodeMessageType.POSTREPLIES_RESPONSE:
                {
                    // current post reply messages
                    client.onRequestedPostReplies(Message.postId, Message.postReplies);
                }
                break;
                
                // x-ode user replied posts pending notices
                case xodeMessageType.PENDINGNOTICES_DISPATCH:
                {
                    // user pending notices dispatched by service
                    client.onUserPendingNotices(Message.userId, Message.pendingNotices);
                }
                break;

                // x-ode post set as private confirm
                case xodeMessageType.PRIVATEPOST_CONFIRM:
                {
                    // to-private post Id
                    client.onPostAsPrivate(Message.postId, Message.success);
                }
                break;

                // x-ode post set as private notice
                case xodeMessageType.PRIVATEPOST_NOTICE:
                {
                    // to-private post Id
                    client.onPostAsPrivateNotice(Message.postId);
                }
                break;

                // x-ode post set as public confirm
                case xodeMessageType.PUBLICPOST_CONFIRM:
                {
                    // to-public post Id
                    client.onPostAsPublic(Message.postId, Message.success);
                }
                break;

                // x-ode post set as public notice
                case xodeMessageType.PUBLICPOST_NOTICE:
                {
                    // to-public post Id
                    client.onPostAsPublicNotice(Message.postId);
                }
                break;

                // x-ode user comunity (connected users)
                case xodeMessageType.USERCOMUNITY_RESPONSE:
                {
                    // user comunity
                    client.onUserComunityConnections(Message.userId, Message.userComunity);
                }
                break;

                // service availability ping acknowledgement
                case xodeMessageType.PING_ACK:
                {
                    // ping acknowledgement
                    client.onPingACK(Message.userId);
                }
                break;

                // any other message 
                default:
                {
                    // not handled message 
                }
                break;
            }
        };
    }

    // Send Login to server
    LogIn() 
    {
        //alert(this.socket.readyState);
    
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
        }
        else
        {
            let Message =
            {
                messageType: xodeMessageType.LOGIN_REQUEST,

                userEnvID: this.sessionInfo.userEnvID
            }

            var message = JSON.stringify(Message);

            this.socket.send(message);
        }
    }

    // Authenticate(Sign In) User on service
    AuthenticateUser(username, password)
    {
        //alert(this.socket.readyState);
    
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
        }
        else
        {
            let Message =
            {
                messageType: xodeMessageType.SIGNIN_REQUEST,

                userId: this.sessionInfo.userId,
                                
                username: username,
                password: password
            }

            var message = JSON.stringify(Message);

            this.socket.send(message);
        }
    }

    // Register(Sign Up) User on service
    RegisterUser(email, password)
    {
        //alert(this.socket.readyState);
    
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
        }
        else
        {
            let Message =
            {
                messageType: xodeMessageType.SIGNUP_REQUEST,

                userId: this.sessionInfo.userId,
                                
                email: email,
                password: password
            }

            var message = JSON.stringify(Message);

            this.socket.send(message);
        }
    }

    // Sign Out User of service
    LogoutUser()
    {
        //alert(this.socket.readyState);
    
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
        }
        else
        {
            let Message =
            {
                messageType: xodeMessageType.LOGOUT_REQUEST,

                userId: this.sessionInfo.userId,
                                
                connectionId: this.connID
            }

            var message = JSON.stringify(Message);

            this.socket.send(message);
        }
    }
    
    // Send request for Posts to server
    GetPosts() 
    {
        //alert(this.socket.readyState);
    
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
        }
        else
        {
            let Message =
            {
                messageType: xodeMessageType.FEATUREPOSTSLIST_REQUEST,

                userId: this.sessionInfo.userId
            }

            var message = JSON.stringify(Message);

            this.socket.send(message);
        }
    }

    // Send Add Post to server
    AddNewPost(featureJson) 
    {
        //alert(this.socket.readyState);

        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
        }
        else
        {
            let Message =
            {
                messageType: xodeMessageType.ADDNEWPOST_REQUEST,

                userId: this.sessionInfo.userId,

                featurePost: featureJson
            }

            var message = JSON.stringify(Message);

            this.socket.send(message);
        }
    }

    // Send Delete Post to server
    DeletePost(postId) 
    {
        //alert(this.socket.readyState);

        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
        }
        else
        {
            let Message =
            {
                messageType: xodeMessageType.DELETEPOST_REQUEST,

                userId: this.sessionInfo.userId,

                postId: postId
            }

            var message = JSON.stringify(Message);

            this.socket.send(message);
        }
    }

    // Send add new reply message for post request to server
    ReplyToPost(newPostMessage) 
    {
        //alert(this.socket.readyState);

        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
        }
        else
        {
            let Message =
            {
                messageType: xodeMessageType.REPLIEDPOST_REQUEST,

                userId: this.sessionInfo.userId,

                postReply: newPostMessage
            }

            var message = JSON.stringify(Message);

            this.socket.send(message);
        }
    }    
    
    // Send add new reply message for post request to server
    GetPostReplies(postId)
    {
        //alert(this.socket.readyState);

        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
        }
        else
        {
            let Message =
            {
                messageType: xodeMessageType.POSTREPLIES_REQUEST,

                userId: this.sessionInfo.userId,
                
                postId: postId,
            }

            var message = JSON.stringify(Message);

            this.socket.send(message);
        }
    }

    // Request user offline pending notices from service
    GetPendingNotices()
    {
        //alert(this.socket.readyState);

        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
        }
        else
        {
            let Message =
            {
                messageType: xodeMessageType.PENDINGNOTICES_REQUEST,

                userId: this.sessionInfo.userId
            }

            var message = JSON.stringify(Message);

            this.socket.send(message);
        }
    }

    // request post to private
    PrivatePost(postId) 
    {
        //alert(this.socket.readyState);

        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
        }
        else
        {
            let Message =
            {
                messageType: xodeMessageType.PRIVATEPOST_REQUEST,

                userId: this.sessionInfo.userId,

                postId: postId
            }

            var message = JSON.stringify(Message);

            this.socket.send(message);
        }
    }

    // request post to public
    PublicPost(postId) 
    {
        //alert(this.socket.readyState);

        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
        }
        else
        {
            let Message =
            {
                messageType: xodeMessageType.PUBLICPOST_REQUEST,

                userId: this.sessionInfo.userId,

                postId: postId
            }

            var message = JSON.stringify(Message);

            this.socket.send(message);
        }
    }

    // request user comunity (connections)
    GetUserComunity()
    {
        //alert(this.socket.readyState);

        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
        }
        else
        {
            let Message =
            {
                messageType: xodeMessageType.USERCOMUNITY_REQUEST,

                userId: this.sessionInfo.userId
            }

            var message = JSON.stringify(Message);

            this.socket.send(message);
        }
    }

    // Terminate connection to x-ode WebSocket
    Close()
    {
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
        }
        this.socket.close(1000, xodeActionMessage.CLIENTCLOSE_ACTION);
        //alert('socket closed');
    }

    // ping check request
    sendPingCHK() // PING_CHK <-> PING_ACK
    {
        //alert(this.socket.readyState);

        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) 
        {
            //alert('socket not connected');
            return false;
        }
        else
        {
            let Message =
            {
                messageType: xodeMessageType.PING_CHK,

                userId: this.sessionInfo.userId,
                
                authToken: this.sessionInfo.authToken
            }

            var message = JSON.stringify(Message);

            this.socket.send(message);

            return true;
        }
    }

}

// deploy x-ode service websocket url
const wsapiconnUrl = process.env.REACT_APP_SERVICE_WS_API;

// x-ode WebSocket connection instance 
const xodeWS = new WSapi(wsapiconnUrl);
export default xodeWS;

// x-ode WebSocket communication protocol messages types
const xodeMessageType = 
{
    UNDEFINED: 0, // void action flag
    CONNECT_ID: 1, // delivery of connection id to client
    LOGIN_REQUEST: 2, // anonimity user login attempt
    LOGIN_CONFIRM: 3, // anonimity user login confirmation
    LOGOUT_REQUEST: 4, // user logout
    LOGOUT_CONFIRM: 5, // user logout confirmation
    SIGNUP_REQUEST: 6, // user signing attempt for signed mode access
    SIGNUP_CONFIRM: 7, // user signing for signed mode access confirmation
    SIGNOFF_REQUEST: 8, // user registration cancelation
    SIGNIN_REQUEST: 9, // signed mode user login attempt
    SIGNIN_CONFIRM: 10, // signed mode user login confirmation
    FEATUREPOSTSLIST_REQUEST: 11, // request posts available for user as features list
    FEATUREPOSTSLIST_RESPONSE: 12, // delivery of posts available for user as features list
    FEATUREPOST_REQUEST: 13, // request specific post as feature
    FEATUREPOST_RESPONSE: 14, // delivery of specific post as feature
    FEATUREPOSTSALL_REQUEST: 15, // request all posts as features list
    FEATUREPOSTSALL_RESPONSE: 16, // deliver all posts as features list
    ADDNEWPOST_REQUEST: 17, // request to add new post
    ADDNEWPOST_CONFIRM: 18, // new post added confirmation to creator
    NEWPOST_NOTICE: 19, // new post added notice
    DELETEPOST_REQUEST: 20, // request to delete a post
    DELETEPOST_CONFIRM: 21, // post deleted confirmation
    DELETEPOST_NOTICE: 22, // post deleted notice
    MODIFYPOST_REQUEST: 23, // change/update post request
    MODIFYPOST_CONFIRM: 24, // post updated confirmation
    MODIFYPOST_NOTICE: 25, // post updated notice
    REPLIEDPOST_REQUEST: 26, // repply to post action
    REPLIEDPOST_CONFIRM: 27, // repply to post confirmation
    REPLIEDPOST_NOTICE: 28, // repply to post notice
    CLAIMPOST_REQUEST: 29, // post creator user request to own post
    CLAIMPOST_CONFIRM: 30, // post ownership claim confirmation
    CLAIMPOST_NOTICE: 31, // post claimed and owned notice
    PRIVATEPOST_REQUEST: 32, // owner user request to set post available only to current participants
    PRIVATEPOST_CONFIRM: 33, // post set to private confirmation
    PRIVATEPOST_NOTICE: 34, // post set to private notice
    PUBLICPOST_REQUEST: 35, // owner user request to set post available to any user
    PUBLICPOST_CONFIRM: 36, // post set to public confirmation
    PUBLICPOST_NOTICE: 37, // post set to public notice
    POSTREPLIES_REQUEST: 38, // request post current replies
    POSTREPLIES_RESPONSE: 39, // delivery of post current replies
    PENDINGNOTICES_REQUEST: 40, // request for user offline pending notices 
    PENDINGNOTICES_DISPATCH: 41, // user offline pending notices dispatched 
    USERCOMUNITY_REQUEST: 42, // user comunity (connected users) request 
    USERCOMUNITY_RESPONSE: 43, // user comunity (connected users) response
    PING_CHK: 44, // service availability ping checking
    PING_ACK: 45 // service availability ping acknowledgement
}
Object.freeze(xodeMessageType);

// x-ode WebSocket communication protocol action messages
const xodeActionMessage = 
{
    CLIENTOPEN_ACTION: 'CLIENTOPEN_ACTION', // ws api client connect action
    CLIENTCLOSE_ACTION: 'CLIENTCLOSE_ACTION' // ws api client disconnect
}
Object.freeze(xodeActionMessage);