import React from 'react';

import Button from '../Button/Button';

import './AccessDialog.css';

class AccessDialog extends React.Component
{
  constructor(props)
  {
    super(props);

    this.Code = 'test';
    this.CodeText = 'Enter access code here';

    this.state =
    {
      Code: this.CodeText,
      CodeValid: false,
      changedCode: false
    };

    this.onSubmitDialog = this.props.callbackSubmit;

    this.onSubmit = this.onSubmit.bind(this);

    this.OnCodeChanged = this.OnCodeChanged.bind(this);
    this.OnCodeFocus = this.OnCodeFocus.bind(this);
  }

  onSubmit()
  {
    if (this.state.Code === this.Code)
    {
      this.onSubmitDialog();
    }
    else
    {
      this.setState(
      {
        Code: this.CodeText,
        CodeValid: false,
        changedCode: false
      });
    }
  }

  OnCodeChanged(e)
  {
    let newCode = (e.target.value === null || e.target.value === undefined || e.target.value === ' ' || e.target.value === '') ? this.CodeText : e.target.value;
    
    this.setState(
    {
      Code: newCode,
      CodeValid: ((newCode !== '') && (newCode !== this.CodeText)),

      changedCode: true
    });
  }

  OnCodeFocus(e)
  {
    if (!this.state.changedCode || !this.state.CodeValid)
    {
      let newCode = '';
      e.target.value = newCode;

      this.setState(
      {
        Code: newCode,
        CodeValid: false,
        changedCode: true
      });
    }
  }

  render()
  {
    return (

      <div className="AccessDialog">

        <div className="WelcomeText">Welcome to x-ode</div>

        <div className="CodeForm">
          <input
            className="CodeInput"
            type="text"

            value={this.state.Code}
            spellCheck="false"

            style={{color: this.state.CodeValid ? 'black' : 'gray'}}

            onChange={this.OnCodeChanged}
            onFocus={this.OnCodeFocus}
            onClick={this.OnCodeFocus}
            onKeyDown={this.OnCodeFocus}
          />
        </div>

        <Button type="submit" position="right-bottom-2" callbackAction={this.onSubmit} tip={'Submit'} alt=">" />

      </div>

    );
  }
}

export default AccessDialog;
