import React from 'react';

import xodeService from '../../../../serviceclient/ServiceClient';
import dateEpoch from '../../../../serviceclient/dateEpoch';
import PostTitleInfo from '../PostTitleInfo/PostTitleInfo';
import MessageThread from '../MessageThread/MessageThread';
import Button from '../../../Button/Button';
import OptionsMenu from '../../../OptionsMenu/OptionsMenu';
import { isMobile } from "react-device-detect";

import './SelectedPostComponent.scss';

class SelectedPostComponent extends React.Component
{
  constructor(props)
  {
    super(props);

    this.state =
    {
      menuOn: false,

      replying: false,

      replyMessage: 'Reply...',
      replyValid: false,

      replyChanged: false
    };

    this.OnCloseSelectedPost = this.OnCloseSelectedPost.bind(this);
    this.OnOptionsMenu = this.OnOptionsMenu.bind(this);

    this.OnMakePrivate = this.OnMakePrivate.bind(this);
    this.OnMakePublic = this.OnMakePublic.bind(this);
    this.OnDeletePost = this.OnDeletePost.bind(this);
    this.OnDeleteConfirm = this.OnDeleteConfirm.bind(this);
    this.OnDeleteCancel = this.OnDeleteCancel.bind(this);
    this.OnCopyLink = this.OnCopyLink.bind(this);
    this.OnReplyToPost = this.OnReplyToPost.bind(this);

    this.OnReplyChanged = this.OnReplyChanged.bind(this);
    this.OnReplyFocus = this.OnReplyFocus.bind(this);
    this.OnCancelReply = this.OnCancelReply.bind(this);
    this.OnSubmitReply = this.OnSubmitReply.bind(this);

    this.OnCancelConfirm = this.OnCancelConfirm.bind(this);
    this.OnCancelClose = this.OnCancelClose.bind(this);
    this.OnCloseConfirm = this.OnCloseConfirm.bind(this);

    this.menuBtnModeOverride = this.menuBtnModeOverride.bind(this);

    // go to post callback, from messages, to id on link
    this.goToPostCallback = this.goToPostCallback.bind(this);
  }

  // go to post callback, from messages, to id on link
  goToPostCallback(postId)
  {
    this.props.goToPostCallback(postId);
  }

  resetSelectedPost()
  {
    // reset inital status
    this.setState(
    {
      menuOn: false,

      replying: false,

      replyMessage: 'Reply...',
      replyValid: false,

      replyChanged: false
    });

    //reset overrided mode btn
    if (this.optionsBtnModeOverride)
    {
      this.optionsBtnModeOverride('normal');
    }
  }

  doneSelectedPost()
  {
    // dissmiss new post popup
    this.props.callbackClose();

    // reset inital status
    this.resetSelectedPost();
  }

  OnMakePrivate()
  {
    // close menu
    this.setState(
    {
      menuOn: false
    });

    // request set post as private
    xodeService.privatePost(this.props.post.properties.postId);

    // close popup
    this.doneSelectedPost();
  }

  OnMakePublic()
  {
    // close menu
    this.setState(
    {
      menuOn: false
    });

    // request set post as public
    xodeService.publicPost(this.props.post.properties.postId);

    // close popup
    this.doneSelectedPost();
  }

  OnDeletePost()
  {
    // close menu
    this.setState(
    {
      menuOn: false
    });

    // confirm deletion
    this.props.confirmDialog("Do you really want to delete this post?", this.OnDeleteConfirm, this.OnDeleteCancel);
  }

  OnDeleteConfirm()
  {
    // proceed to delete request
    xodeService.deletePost(this.props.post.properties.postId);

    // close popup
    this.doneSelectedPost();
  }

  OnDeleteCancel()
  {
    // close menu
    this.setState(
    {
      menuOn: false
    });

    // keep on state
  }

  OnCopyLink()
  {
    // get web app url
    let url = window.location.href;
    let tag = url.indexOf('#');
    if (tag > 0)
    {
      url = url.substring(0, tag);
    }

    // grab link
    navigator.clipboard.writeText(url + '#' + this.props.post.properties.postId);

    // close menu
    this.setState(
    {
      menuOn: false
    });
  }

  OnReplyToPost()
  {
    // set reply area state
    this.setState(
    {
      replyMessage: 'Reply...',
      replyValid: false,

      replyChanged: false,

      replying: true
    });
  }

  OnReplyChanged(e)
  {
    let newReply = (e.target.value === null || e.target.value === undefined ? '' : e.target.value);

    this.setState(
    {
      replyMessage: newReply,
      replyValid: (newReply !== ''),

      replyChanged: true
    });
  }

  OnReplyFocus(e)
  {
    if (!this.state.replyChanged || !this.state.replyValid)
    {
      let newReply = '';
      e.target.value = newReply;
      this.setState(
      {
        replyMessage: newReply,
        replyValid: false,
        replyChanged: true
      });
    }
  }

  OnSubmitReply()
  {
    if (this.state.replyValid)
    {
      // set reply area state
      this.setState(
      {
        replying: false
      });

      // set new Reply Message
      let newPostReply = xodeService.setReplyMessage(dateEpoch.currentDateTime(), this.props.post.properties.postId, this.state.replyMessage);

      // add post reply message request
      xodeService.replyToPost(newPostReply);
    }
    else
    {
      this.setState(
      {
        replyChanged: true,
        replyMessage: 'Reply...'
      });
    }
  }

  OnCancelReply()
  {
    if (this.state.replyChanged && this.state.replyValid)
    {
      this.props.confirmDialog("Do you really want to discard your reply?", this.OnCancelConfirm, this.OnCancelClose);
    }
    else
    {
      // set reply area state
      this.setState(
      {
        replying: false
      });
    }
  }

  OnCancelConfirm()
  {
    // set confirm dialog and reply area status
    this.setState(
    {
      replying: false
    });
  }

  OnCancelClose()
  {
    // keep on state
  }

  OnCloseConfirm()
  {
    // discard and close
    this.doneSelectedPost();
  }

  OnCloseSelectedPost()
  {
    if (this.state.replying && this.state.replyChanged)
    {
      this.props.confirmDialog("Do you really want to discard your reply?", this.OnCloseConfirm, this.OnCancelClose);
    }
    else
    {
      this.doneSelectedPost();
    }
  }

  OnOptionsMenu()
  {
    // set menu state
    this.setState(
    {
      menuOn: !this.state.menuOn
    });
  }

  menuBtnModeOverride(modeOverride)
  {
    this.optionsBtnModeOverride = modeOverride;
  }

  renderMenu(side)
  {
    let menuOptions =
    [
      {
        text: (this.props.post.properties.isPublic) ? 'Make private' : 'Make public',
        callback: (this.props.post.properties.isPublic) ? this.OnMakePrivate : this.OnMakePublic,
        enabled: (this.props.post.properties.userId === xodeService.currentUser.userId) && xodeService.currentUser.signedMode
      },
      {
        text: 'Delete conversation',
        callback: this.OnDeletePost,
        enabled: (this.props.post.properties.userId === xodeService.currentUser.userId)
      },
      {
        text: 'Copy link',
        callback: this.OnCopyLink,
        enabled: true
      }
    ];

    return (
      <OptionsMenu options={menuOptions} place={'popup-'+side} />
    );
  }

  componentDidUpdate(prevProps, prevState)
  {
    if (this.props.post.properties.postId === prevProps.post.properties.postId)
    {
      // if updated by new reply, then scroll to messages thread end
      if (this.props.postReplies !== prevProps.postReplies)
      {
        this.messagesEnd.scrollIntoView();
      }
    }
    else
    {
      //if selecting new post, reset initial state
      this.resetSelectedPost();
    }

    if (prevState.replying != this.state.replying) {
      if (isMobile) {
        if (this.state.replying) {
          // 122 is the height of the reply area.
          this.props.map.panBy([0, 122]);
        }
        else {
          this.props.map.panBy([0, -122]);
        }
      }
    }
  }

  render()
  {
    const buttonsSide = (this.props.anchor === 'right') ? 'left' : 'right' ;

    return (

      <div className="SelectedPostComponent" >

        <PostTitleInfo title={this.props.post.properties.title} epochFrom={this.props.post.properties.epochFrom} epochTo={this.props.post.properties.epochTo} />

        <div className="PostSectionsSeparator" />

        <div className="MessagesArea" >

          <MessageThread post={this.props.post} replies={this.props.postReplies} currentUser={xodeService.currentUser.userId} userComunity={this.props.userComunity} goToPostCallback={this.goToPostCallback} />

          <div style={{ float:"left", clear:"both" }} ref={(el) => { this.messagesEnd = el; }} />

        </div>

        {
          this.state.replying &&
          <div className="ReplyArea" >

            <div className="PostSectionsSeparator" />

            <textarea
              className="ReplyContentIn"
              rows="5"

              value={this.state.replyMessage}

              style={{color: this.state.replyValid ? 'black' : (this.state.replyChanged ? 'red' : 'gray')}}

              onChange={this.OnReplyChanged}
              onFocus={this.OnReplyFocus}
              onClick={this.OnReplyFocus}
            />

            <Button type="close" position={'right-bottom-2 popup Button--replying'} callbackAction={this.OnCancelReply} tip={'Cancel reply'} alt="X" />
            <Button type="submit" position={'right-bottom-3 popup Button--replying'} callbackAction={this.OnSubmitReply} tip={'Submit reply'} alt=">" />

          </div>
        }

        <Button type="close" position={'top-'+buttonsSide+'-1 popup'} callbackAction={this.OnCloseSelectedPost} tip={'Close'} alt="X" />
        <Button type="menu" position={'top-'+buttonsSide+'-2 popup'} callbackAction={this.OnOptionsMenu} rolloverOverride={this.state.menuOn} modeOverride={this.menuBtnModeOverride} tip={'Options'} alt=":" />
        {!this.state.replying && <Button type="submit" position={'bottom-'+buttonsSide+'-2 popup'} callbackAction={this.OnReplyToPost} tip={'Reply'} alt=">" />}

        {this.state.menuOn && this.renderMenu(buttonsSide)}

      </div>

    );
  }
}

export default SelectedPostComponent;
