import React from 'react';

import dateEpoch from '../../../../serviceclient/dateEpoch';

import './Message.scss';

class Message extends React.Component
{
  constructor(props)
  {
    super(props);

    this.OnPostLinkClicked = this.OnPostLinkClicked.bind(this);
  }

  contentTohtml(text)
  {
    let htmlContent = [];

    let startIndex = 0;

    let urlRegExp = new RegExp(/((?:(https?|ftp|rtsp):\/\/(?:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,64}(?:\:(?:[a-zA-Z0-9\$\-\_\.\+\!\*\'\(\)\,\;\?\&\=]|(?:\%[a-fA-F0-9]{2})){1,25})?\@)?)?((?:(?:[a-zA-Z0-9][a-zA-Z0-9\-]{0,64}\.)))+(?:([a-zA-Z0-9][a-zA-Z0-9\\-]{1,25})|(?:(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}|[1-9][0-9]|[0-9])))(?:\:\d{1,5})?)(\/(?:(?:[a-zA-Z0-9\;\/\?\:\@\&\=\#\~\-\.\+\!\*\'\(\)\,\_])|(?:\%[a-fA-F0-9]{2}))*)?(?:\b|$)/gi);
    let xodePostRegExp = /((https:\/\/)*)(x-ode)(((-web)|(-tuneup))*)(\.)(\S+)(\/#)[0-9]+/i;

    let urlMatch = [];

    while ((urlMatch = urlRegExp.exec(text)) != null) 
    {           
      htmlContent.push(
        <span>{text.substr(startIndex, (urlMatch.index - startIndex))}</span>
      );    

      let urlLink = text.substr(urlMatch.index, (urlMatch[0].length));

      if (xodePostRegExp.test(urlLink))
      {
        //link to post

        let tag = urlLink.indexOf('/#');
        let postId = urlLink.substring(tag+2);

        //remove 'https://'
        let protocolEnd = '://';
        let protocolEndPosition = urlLink.indexOf(protocolEnd);
        let toremove = (protocolEndPosition >= 0) ? protocolEndPosition + 3 : 0;
        let cleanPostLink = urlLink.substring(toremove);

        htmlContent.push(
          <span id={postId} onClick={this.OnPostLinkClicked} style={{cursor:'pointer', color:'blue'}}>{cleanPostLink}</span>
        );
      }
      else
      {
        // internet link

        let uppLink = urlLink.toUpperCase();
        let outRef = (uppLink.startsWith('HTTP')||uppLink.startsWith('FTP')||uppLink.startsWith('RTSP'))?'':'//';
        htmlContent.push(
          <a href={outRef+urlLink} target="_blank">{urlLink}</a>
        );
      }

      startIndex = urlMatch.index + (urlMatch[0].length);               
    }
    if (startIndex < text.length)
    {
      htmlContent.push(
        <span>{text.substr(startIndex)}</span>
      );    
    }

    return (htmlContent);
  }

  OnPostLinkClicked(e)
  {
    let postId = parseInt(e.target.id);
    this.props.goToPostCallback(postId);
  }

  render()
  {
    let content = this.contentTohtml(this.props.content);

    let timeSubmit = dateEpoch.epochToDateTime(this.props.epochSubmit).toLocaleString();
    let details = this.props.author + ' ' + timeSubmit;

    return (
      <div className="Message" >
        <div className="MessageInfoArea" >
          <div className={'MessageContent '+this.props.from}>{content}</div>
          <div className={'MessageDetails '+this.props.from+' '+this.props.by}>{details}</div>
        </div>
      </div>
    );
  }
}

export default Message;