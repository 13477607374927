import React from 'react';
import ReactDOM from 'react-dom';

import PopUp from './PopUp/PopUp';

import './PopUpComponent.scss';

//Mapbox SDK
import mapboxgl from 'mapbox-gl';

class PopUpComponent extends React.Component
{
    constructor(props) 
    {
        super(props);

        //map popup Container
        this.popupNode = document.createElement('div');

        //map popup
        this.popup = null;
    }

    // pre update cleanup init
    componentWillUpdate() 
    {
        if (this.popup)
        {
            this.popup.remove();
        }
    }

    // setup updated
    componentDidUpdate() 
    {
        if (this.props.location)
        {
            //locate anchor
            this.anchor = this.props.anchor;

            // set mapbox sdk popup instance
            this.popup = new mapboxgl.Popup(
            { 
                //offset: 45,//displacement of "2" in vertical as pointer composition effect 
                offset:
                {
                    'top-left': [-65, 40],
                    'bottom-right': [68, -40],
                    'left': [45, 2],
                    'right': [-45, 2]
                },
                anchor: this.anchor,
                closeButton: false,
                closeOnClick: true,
                closeOnMove: false
            });

            if (window.$isMobile) this.popup.setMaxWidth("none");

            // render popup
            ReactDOM.render(<PopUp location={this.props.location} anchor={this.anchor} postComponent={this.props.postComponent} isBalloon={this.props.isBalloon} />, this.popupNode);

            // set popup on map
            this.popup.setLngLat(this.props.location).setDOMContent(this.popupNode).addTo(this.props.map);
        }
    }

    componentWillUnmount()
    {
        // release previous popup instance
        return () => this.popup.remove();
    }

    render()
    {
        return (
            this.props.location 
                ? <div className="PopUpComponent"/> 
                : null
        );
    }
}

export default PopUpComponent;